import { useState, useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import { useDispatch } from "react-redux";
import { ethers } from "ethers";

import { alertFailure } from "../store/actions/alert";
import { useTypedSelector } from "../hooks/useTypedSelector";
import { getErrorMessage } from "../utils/getErrorMessage";
import { BaseRequest } from "../request/Request";
import { tokenActions } from "../store/constants/token";

const MESSAGE_INVESTOR_SIGNATURE =
  process.env.REACT_APP_MESSAGE_INVESTOR_SIGNATURE || "";

const rawMessage = MESSAGE_INVESTOR_SIGNATURE;
const rawMessageLength = new Blob([rawMessage]).size;
const message = ethers.utils.toUtf8Bytes(
  "\x19Ethereum Signed Message:\n" + rawMessageLength + rawMessage
);
const messageHash = ethers.utils.keccak256(message);

const useWalletSignatureWithRefreshToken = (refreshing: boolean = false) => {
  const dispatch = useDispatch();
  const connector = useTypedSelector((state) => state.connector).data;
  const { provider: library, account: connectedAccount } = useWeb3React();
  const baseRequest = new BaseRequest();
  const [signature, setSignature] = useState("");

  const signMessage = useCallback(async () => {
    try {
      if (!refreshing) return;
      if (connectedAccount && library && connector) {
        const prod = new ethers.providers.Web3Provider(library.provider);

        const singer = prod?.getSigner();
        const sig = await singer?.signMessage(MESSAGE_INVESTOR_SIGNATURE);
        const response = (await baseRequest.post(`/user/login`, {
         signature: sig,
         wallet_address: connectedAccount,
       })) as any;

       const resObj = await response.json();

       if (resObj.status && resObj.status === 200 && resObj.data) {
         const { token } = resObj.data;
         localStorage.setItem(
           `access_token:${connectedAccount}`,
           token.token
         );
         dispatch({ type: tokenActions.TOKEN_REFRESH_SUCCESS });
         window.location.reload();
       }

      } else {
        dispatch({ type: tokenActions.TOKEN_REFRESH_FAIL });
      }
    } catch (err: any) {
      console.log("useWalletSignature", err);
      dispatch(alertFailure(getErrorMessage(err)));
    }
  }, [library, connector, connectedAccount, refreshing]);

  return {
    signMessage,
    signature,
    setSignature,
  };
};

export default useWalletSignatureWithRefreshToken;
