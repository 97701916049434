import React from "react";
import Button from "../Button";
import BigNumber from "bignumber.js";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import {
  BSC_CHAIN_ID,
  ETH_CHAIN_ID,
  POLYGON_CHAIN_ID,
  AVALANCHE_CHAIN_ID,
  ARBITRUM_CHAIN_ID,
  BASE_CHAIN_ID,
} from "../../../constants/network";
import { NETWORK_AVAILABLE, PUBLIC_WINNER_STATUS } from "../../../constants";
import { isMobile } from "react-device-detect";

function ApplyWhiteListButton(props: any) {
  const today = new Date();
  const {
    poolDetails,
    joinTimeInDate,
    endJoinTimeInDate,
    currentUserTier,
    connectedAccount,
    wrongChain,
    verifiedEmail,
    disableAllButton,
    alreadyJoinPool,
    joinPoolSuccess,
    poolJoinLoading,
    joinPool,
    winnersList,
    ableToFetchFromBlockchain,
  } = props;

  const { appChainID } = useTypedSelector(
    (state: any) => state.appNetwork
  ).data;
  const appNetwork = (() => {
    switch (appChainID) {
      case BSC_CHAIN_ID:
        return NETWORK_AVAILABLE.BSC;
      case BASE_CHAIN_ID:
        return NETWORK_AVAILABLE.BASE;
      case POLYGON_CHAIN_ID:
        return NETWORK_AVAILABLE.POLYGON;
      case AVALANCHE_CHAIN_ID:
        return NETWORK_AVAILABLE.AVALANCHE;
      case ARBITRUM_CHAIN_ID:
        return NETWORK_AVAILABLE.ARBITRUM;
      case ETH_CHAIN_ID:
      default:
        return NETWORK_AVAILABLE.ETH;
    }
  })();

  const availableJoin =
    poolDetails?.method === "whitelist" && joinTimeInDate && endJoinTimeInDate
      ? today >= joinTimeInDate &&
        today <= endJoinTimeInDate &&
        currentUserTier &&
        connectedAccount &&
        !wrongChain &&
        new BigNumber(currentUserTier?.level || 0).gte(poolDetails?.minTier) &&
        verifiedEmail
      : false;
  const matchNetwork = appNetwork == poolDetails?.networkAvailable;
  const disableButton =
    !availableJoin ||
    alreadyJoinPool ||
    joinPoolSuccess ||
    disableAllButton ||
    !matchNetwork;
  const readyJoin = alreadyJoinPool || joinPoolSuccess;

  const hideButton =
    ableToFetchFromBlockchain &&
    verifiedEmail &&
    winnersList &&
    winnersList.total > 0 &&
    poolDetails?.publicWinnerStatus == PUBLIC_WINNER_STATUS.PUBLIC;
  if (hideButton) {
    return <></>;
  }

  return (
    <>
      <Button
        text={readyJoin ? "Registered Interest" : "Register Interest"}
        color={"white"}
        style={{
          width: "100%",
          height: 36,
          background: "transparent",
          borderRadius: 60,
          color: "white",
          font: "normal normal 500 14px/20px ",
          border: "none",
          padding: 8,
          // position: isMobile ? 'initial' : 'absolute',
          margin: isMobile ? "7px auto" : "unset",
        }}
        loading={poolJoinLoading}
        onClick={joinPool}
        // disabled={disableButton}
      />
    </>
  );
}

export default ApplyWhiteListButton;
