import useStyles from "./style";

const streamLine = [
  {
    numb: "01",
    title: "Application",
    des: "Projects apply on the RWA Governance Portal.",
  },
  {
    numb: "02",
    title: "Due Diligence",
    des: "The RWA Research team reviews applications and moves selected projects to the next step.",
  },
  {
    numb: "03",
    title: "The RWA Council",
    des: "The DAO Council receives the selected projects and makes a final decision.",
  },
  {
    numb: "04",
    title: "Pre-paid Support",
    des: "Once approved, projects get the full RWA team support leading up to the sale.",
  },
  {
    numb: "05",
    title: "IDO",
    des: "Launch your token on the official RWA website.",
  },
];

const StreamLined = () => {
  const styles = useStyles();

  return (
    <div className={styles.main}>
      <h2>Streamlined application and launch process</h2>
      <div className={styles.streamAndLine}>
        <div className={styles.streamContent}>
          {streamLine?.map((value, index) => {
            if (index % 2 === 0) {
              return (
                <div key={value?.numb} className={styles.streamContentItem}>
                  <div>
                    <h6>{value?.numb}</h6>
                  </div>
                  <div>
                    <h6>{value?.title}</h6>
                    <p>{value?.des}</p>
                  </div>
                </div>
              );
            }
            return <div />;
          })}
        </div>
        <div className={styles.streamLine}>
          <div className={styles.streamLineL}></div>
          <div>
            <p />
          </div>
          <div>
            <p />
          </div>
          <div>
            <p />
          </div>
          <div>
            <p />
          </div>
          <div>
            <p />
          </div>
        </div>
        <div className={styles.streamContent}>
          {streamLine?.map((value, index) => {
            if (index % 2 === 0) {
              return <div />;
            }
            return (
              <div key={value?.numb} className={styles.streamContentItem}>
                <div>
                  <h6>{value?.numb}</h6>
                </div>
                <div>
                  <h6>{value?.title}</h6>
                  <p>{value?.des}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.streamAndLineMobile}>
        {streamLine?.map((value, index) => {
          return (
            <div key={value?.numb} className={styles.streamContentItem}>
              <div>
                <h6>{value?.numb}</h6>
              </div>
              <div>
                <h6>{value?.title}</h6>
                <p>{value?.des}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default StreamLined;
