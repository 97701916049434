import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    modalConfirm: {
      "& .MuiPaper-root": {
        [theme.breakpoints.down("xs")]: {
          margin: "28px",
        },
      },
      "& .modal-content": {

        width: "440px",
        padding: "32px 40px 28px",
        backgroundColor: "#09090E",
        border: "1px solid #353539",
        borderRadius: "2px",
        color: "#FFFFFF",
        [theme.breakpoints.down("xs")]: {
          padding: "40px 20px 32px",
        },
      },
      "& .modal-content__head": {
        padding: 0,
      },
      "& .modal-content__head .title": {
        margin: "0 auto",
        font: "normal normal 500 22px/28px ",
      },
      "& .modal-content__body": {
        padding: "0",
        margin: 0,
        font: "14px/24px ",

        "& .token-type": {
          display: "flex",
          justifyContent: "space-between",
          fontSize: "16px",
          alignItems: "center",
          marginBottom: "14px",
        },
        "& .token-detail": {
          display: "flex",
          alignItems: "center",
        },
        "& .token-logo": {
          width: "22px",
          height: "22px",
          borderRadius: "28px",
          margin: "0 5px",
        },

        "& .input-group": {
          background: "#222228",
          padding: "0 10px",
          borderRadius: "4px",
        },
        "& .input-group input": {
          cursor: "default",
          padding: "0",
        },
        "& .token-balance": {
          color: "#AEAEAE",
          marginTop: "5px",
        },
        "& .subtitle": {
          marginBottom: "5px",
          fontWeight: 700,
          color: "#FFFFFF",
        },
      },
      "& .modal-content__foot": {
        borderTop: "1px solid #727272",
        padding: "12px 0px 0px",
      },
    },
    btnYes: {
      width: "100% !important" as any,
      height: "36px !important" as any,
      font: "normal normal 500 14px/20px  !important" as any,
      borderRadius: "4px !important" as any,
      background: "#6788FF",
      marginBottom: "0 !important" as any,
      padding: "0 !important" as any,
    },
    btnCancel: {
      width: "100% !important" as any,
      height: "36px !important" as any,
      font: "normal normal 500 14px/20px  !important" as any,
      borderRadius: "4px !important" as any,
      background: "#727272",
      marginBottom: "0 !important" as any,
      padding: "0 !important" as any,
    },
    notice: {
      font: "normal normal 500 14px/18px ",
      color: "#FFF",
      marginTop: "30px",
    },
    [theme.breakpoints.down("xs")]: {
      modalTransaction: {
        "& .modal-content__body": {
          padding: "0",
        },
      },
      btnCancel: {
        marginTop: 12,
      },
    },
  };
});

export default useStyles;
