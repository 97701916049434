import { Link } from "react-router-dom";
import useStyles from "./style";
//@ts-ignore
import { Fade } from "react-reveal";
import useFetch from "../../../../hooks/useFetch";
import { useMemo } from "react";

const Welcome = (props: any) => {
  const styles = useStyles();
  const { data } = useFetch<any>(`/statistic`);
  console.log("data: ", data);

  const raise = useMemo(() => {
    const numb = +data?.fund_raised || 0;
    if (numb > 1000000000) {
      return Math.floor(numb / 1000000000) + "B+";
    }
    if (numb > 1000000) {
      return Math.floor(numb / 1000000) + "M+";
    }
    if (numb > 1000) {
      return Math.floor(numb / 1000) + "K+";
    }
    return numb;
  }, [data]);

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <img
          src="/images/landing/hero_img.png"
          className={styles.heroImg}
          alt="hero"
        />
        <div className={styles.filter}>
          <div className={styles.title}>
            <div className={styles.titleHead}>
              <Fade left>
                <h1>
                  <div>Empowering global investment opportunities</div>
                  for everyone - everywhere!
                </h1>
              </Fade>
              <div className="btn-grid">
                <Link to="/pools">
                  <button className={styles.btnApply}>Invest now</button>
                </Link>
                <a
                  href="https://www.rwa.inc/doc"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className={styles.btnDoc}>Documentation</button>
                </a>
              </div>
            </div>
            <div className={styles.line} />
            <div className={styles.titleNumber}>
              <div>
                <h6>{`$${raise}`}</h6>
                <p>Raised</p>
              </div>
              <div>
                <h6>{`+${+data?.number_project || 0} Projects`}</h6>
                <p>In pipeline</p>
              </div>
              <div>
                <h6>+40</h6>
                <p>Partners</p>
              </div>
            </div>
          </div>
          <div className={styles.contentBot}>
            <div className={styles.contentBotSlide}>
              <div className={styles.slide}>
                <div className={styles.slideChild}>
                  Welcome to RWA, a cutting-edge, trusted, and secure platform
                  for Web3 builders and investors. By staking $RWA, you gain
                  privileged early access to acquire project tokens, enhancing
                  your position in the vibrant Web3 ecosystem.
                </div>
              </div>
              <div className={styles.filterSkew} />
            </div>
            <div className={styles.contentBotSupport}>
              <p>Supported exchanges to buy $RWA</p>
              <div className="launchpad-support">
                <a
                  href="https://app.uniswap.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div>
                    <img
                      src="/images/landing/uniswap.svg"
                      width={20}
                      height={20}
                      alt="uniswap"
                    />
                    <span>Uniswap</span>
                  </div>
                </a>
                <a href="https://www.gate.io" target="_blank" rel="noreferrer">
                  <div>
                    <img
                      src="/images/landing/gate.svg"
                      width={20}
                      height={20}
                      alt="gate.io"
                    />
                    <span>Gate.io</span>
                  </div>
                </a>
                <a
                  href="https://www.kucoin.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div>
                    <img
                      src="/images/landing/kucoin.svg"
                      width={20}
                      height={20}
                      alt="kucoin"
                    />
                    <span>Kucoin</span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
