import { Dialog, DialogContent } from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import { BigNumber, ethers } from "ethers";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import LoadingTable from "../../components/Base/LoadingTable";
import DefaultLayout from "../../components/Layout/DefaultLayout";
import { BASE_CHAIN_ID } from "../../constants/network";
import useFetch from "../../hooks/useFetch";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import StakingHeader, {
  BENEFIT_ALL,
  BENEFIT_IDO_ONLY,
  BENEFIT_REWARD_ONLY,
  DURATION_FINISHED,
  DURATION_LIVE,
  POOL_TYPE_ALLOC,
  POOL_TYPE_LINEAR,
} from "./Header";
import useDetailListStakingPool from "./hook/useDetailListStakingPool";
import ModalTransaction from "./ModalTransaction";
import AllocationPool from "./Pool/AllocationPool";
import LinearPool from "./Pool/LinearPool";
import useStyles from "./style";

const closeIcon = "/images/icons/close.svg";
const iconWarning = "/images/warning-red.svg";

const DEFAULT_RPC_URL = process.env.REACT_APP_ETH_RPC_URL || "";

const provider = new ethers.providers.JsonRpcProvider(DEFAULT_RPC_URL);

const StakingPools = (props: any) => {
  const styles = useStyles();

  // Start Staking Logic
  const [blockNumber, setBlockNumber] = useState<number | undefined>(undefined);

  provider.on("block", (num: any) => {
    if (num && Number(num) !== blockNumber) {
      setBlockNumber(Number(num));
    }
  });

  const { appChainID, walletChainID } = useTypedSelector(
    (state) => state.appNetwork
  ).data;
  const { account: connectedAccount } = useWeb3React();

  // Filter
  const [durationType, setDurationType] = useState(DURATION_LIVE);
  const [poolType, setPoolType] = useState(POOL_TYPE_LINEAR);
  const [benefitType, setBenefitType] = useState(BENEFIT_ALL);
  const [stakedOnly, setStakedOnly] = useState(false);
  const [searchString, setSearchString] = useState("");

  // Transaction
  const [openModalTransactionSubmitting, setOpenModalTransactionSubmitting] =
    useState(false);
  const [transactionHashes, setTransactionHashes] = useState([]) as any;
  const { data: poolsList } = useFetch<any>(`/staking-pool`);
  const {
    allocPools,
    linearPools,
    fetchDetailList,
    loading: loadingDetailList,
  } = useDetailListStakingPool(poolsList);

  const [filteredAllocPools, setFilteredAllocPools] = useState([]) as any;
  const [filteredLinearPools, setFilteredLinearPools] = useState([]) as any;
  // Filter allocation | linear Pools
  useEffect(() => {
    let listAlloc = Object.values(allocPools);
    let listLinear = Object.values(linearPools);

    if (durationType === DURATION_FINISHED) {
      listAlloc = listAlloc.filter((e: any) => e?.allocPoint === "0");
      listLinear = listLinear.filter(
        (e: any) =>
          Number(e?.endJoinTime) <= moment().unix() ||
          (BigNumber.from(e?.cap).gt(BigNumber.from("0")) &&
            BigNumber.from(e?.cap)
              .sub(BigNumber.from(e?.totalStaked))
              .eq(BigNumber.from("0")))
      );
    } else {
      listAlloc = listAlloc.filter((e: any) => e?.allocPoint !== "0");
      listLinear = listLinear.filter(
        (e: any) =>
          Number(e?.endJoinTime) > moment().unix() &&
          (BigNumber.from(e?.cap).eq(BigNumber.from("0")) ||
            BigNumber.from(e?.cap)
              .sub(BigNumber.from(e?.totalStaked))
              .gt(BigNumber.from("0")))
      );
    }

    if (benefitType === BENEFIT_REWARD_ONLY) {
      listAlloc = listAlloc.filter((e: any) => e?.point_rate === 0);
      listLinear = listLinear.filter((e: any) => e?.point_rate === 0);
    }

    if (benefitType === BENEFIT_IDO_ONLY) {
      listAlloc = listAlloc.filter((e: any) => e?.point_rate > 0);
      listLinear = listLinear.filter((e: any) => e?.point_rate > 0);
    }

    if (searchString) {
      listAlloc = listAlloc.filter(
        (e: any) =>
          (e?.title as string)
            .toLowerCase()
            .indexOf(searchString.toLowerCase()) !== -1
      );
      listLinear = listLinear.filter(
        (e: any) =>
          (e?.title as string)
            .toLowerCase()
            .indexOf(searchString.toLowerCase()) !== -1
      );
    }

    if (stakedOnly) {
      listAlloc = listAlloc.filter(
        (e: any) =>
          e?.stakingAmount !== "0" || e?.pendingWithdrawal?.amount !== "0"
      );
      listLinear = listLinear.filter(
        (e: any) =>
          e?.stakingAmount !== "0" || e?.pendingWithdrawal?.amount !== "0"
      );
    }
    setFilteredAllocPools(listAlloc);
    setFilteredLinearPools(listLinear);
  }, [
    linearPools,
    allocPools,
    durationType,
    benefitType,
    stakedOnly,
    searchString,
  ]);

  // Get 3 live pools linear
  const linearLivePools = useMemo(() => {
    let listLinear = Object.values(linearPools);
    return listLinear.filter(
      (e: any) =>
        Number(e?.endJoinTime) > moment().unix() &&
        (BigNumber.from(e?.cap).eq(BigNumber.from("0")) ||
          BigNumber.from(e?.cap)
            .sub(BigNumber.from(e?.totalStaked))
            .gt(BigNumber.from("0")))
    );
  }, [linearPools]);

  const reloadData = useCallback(async () => {
    fetchDetailList && fetchDetailList();
  }, [fetchDetailList]);

  const wrongChain = useMemo(() => {
    return false;
    return appChainID !== BASE_CHAIN_ID || appChainID !== walletChainID;
  }, [appChainID, walletChainID]);

  // End Staking Logic
  // Render
  const renderWrongChain = () => {
    return (
      <div className={styles.message}>
        <img src={iconWarning} style={{ marginRight: "12px" }} alt="" />
        Please switch to the Base network to join these staking pools
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <div className={styles.loader}>
        <LoadingTable />
      </div>
    );
  };

  const renderAllocationPools = () => {
    return (
      <>
        <div className={styles.messageDuration}>
          <img src={iconWarning} style={{ marginRight: "12px" }} alt="" />
          UNI-V2 LP-RWA pool has stopped receiving LP-RWA staking. This policy
          is applied from October 4, 2021 until a new announcement.
        </div>
        <div className="pool-area">
          {filteredAllocPools.map((pool: any) => (
            <AllocationPool
              key={pool?.id}
              reload={reloadData}
              setTransactionHashes={setTransactionHashes}
              setOpenModalTransactionSubmitting={
                setOpenModalTransactionSubmitting
              }
              connectedAccount={connectedAccount}
              poolDetail={pool}
              blockNumber={blockNumber}
              poolAddress={pool?.pool_address}
              durationType={durationType}
            />
          ))}
        </div>
      </>
    );
  };

  const renderLinearPools = () => {
    return (
      <>
        <div className="pool-area">
          {filteredLinearPools.map((pool: any) => (
            <LinearPool
              key={pool?.id}
              reload={reloadData}
              setTransactionHashes={setTransactionHashes}
              setOpenModalTransactionSubmitting={
                setOpenModalTransactionSubmitting
              }
              connectedAccount={connectedAccount}
              poolDetail={pool}
              poolAddress={pool?.pool_address}
              durationType={durationType}
              livePools={linearLivePools}
            />
          ))}
        </div>
      </>
    );
  };

  const renderStakingPools = () => {
    if (poolType === POOL_TYPE_ALLOC && filteredAllocPools.length > 0)
      return renderAllocationPools();

    if (poolType === POOL_TYPE_LINEAR && filteredLinearPools.length > 0)
      return renderLinearPools();

    return <></>;
  };

  // End

  return (
    <>
      <div className={styles.wrapper}>
        <div className="content">
          <h1>Staking</h1>
          {wrongChain && renderWrongChain()}

          <StakingHeader
            durationType={durationType}
            setDurationType={setDurationType}
            poolType={poolType}
            setPoolType={setPoolType}
            stakedOnly={stakedOnly}
            setStakedOnly={setStakedOnly}
            benefitType={benefitType}
            setBenefitType={setBenefitType}
            location={props.location}
            history={props.history}
            searchString={searchString}
            setSearchString={setSearchString}
          />

          {loadingDetailList ? renderLoading() : renderStakingPools()}
        </div>

        {openModalTransactionSubmitting && (
          <Dialog
            open={openModalTransactionSubmitting}
            keepMounted
            onClose={() => setOpenModalTransactionSubmitting(false)}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className={styles.submittingDialog}
          >
            <DialogContent className="content">
              <img
                src={closeIcon}
                alt=""
                onClick={() => setOpenModalTransactionSubmitting(false)}
              />
              <img
                src="/images/loading_submit.svg"
                className="loading animate-spin"
                width={48}
                height={48}
                alt="loading"
              />
              <span className="title">Transaction Submitting</span>
            </DialogContent>
          </Dialog>
        )}

        {transactionHashes.length > 0 && (
          <ModalTransaction
            transactionHashes={transactionHashes}
            setTransactionHashes={setTransactionHashes}
            open={transactionHashes.length > 0}
          />
        )}
      </div>
    </>
  );
};

export default withRouter(StakingPools);
