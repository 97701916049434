import useStyles from "./style";

const LaunchWithUs = () => {
  const styles = useStyles();

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.borderGradient}>
          <div className={styles.contentBorderGradient}>
            <h2>Why Launch With Us</h2>
            <div className={styles.launchContent}>
              <div className={styles.launchContentChild}>
                <img
                  src="/images/landing/launch_us.svg"
                  width={53}
                  height={52}
                  alt="raisse"
                />
                <div>
                  <h6>Raise funds publicly</h6>
                  <p>
                    Access a global reach of dedicated backers in a transparent
                    and decentralized setting.
                  </p>
                </div>
              </div>
              <div className={styles.launchContentChild}>
                <img
                  src="/images/landing/launch_us.svg"
                  width={53}
                  height={52}
                  alt="raisse"
                />
                <div>
                  <h6>Build a community</h6>
                  <p>
                    Grow and nurture your most loyal supporters from the very
                    beginning. They’ve got your back!
                  </p>
                </div>
              </div>
              <div className={styles.launchContentChild}>
                <img
                  src="/images/landing/launch_us.svg"
                  width={53}
                  height={52}
                  alt="raisse"
                />
                <div>
                  <h6>Decrease risk</h6>
                  <p>
                    Take full control of your fundraising and leverage RWA’s
                    experience and community.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LaunchWithUs;
