import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    btn: {
      minWidth: 160,
      height: 42,
      borderRadius: 60,
      fontFamily: "",
      fontWeight: 500,
      fontSize: 16,
      lineHeight: "24px",
      textAlign: "center",
      color: "#FFFFFF",
      textTransform: "initial",
      boxShadow: "none",
      border: "none",
      cursor: "pointer",

      [theme.breakpoints.down("sm")]: {
        minWidth: "100%",
      },

      "&.btnStake": {
        background: "#3232DC",
        marginRight: 8,

        [theme.breakpoints.down("sm")]: {
          marginRight: 0,
          marginBottom: 12,
        },

        "&:hover": {
          opacity: 0.85,
        },

        "&:disabled": {
          backgroundColor: "#3232DC !important",
          color: "#FFFFFF",
          cursor: "not-allowed",
          opacity: 0.6,
        },
      },

      "&.btnUnstake": {
        background: "transparent",
        border: "2px solid #6398FF",
        color: "#6398FF",

        "&:hover": {
          opacity: 0.85,
        },

        "&:disabled": {
          color: "#6398FF",
          cursor: "not-allowed",
          opacity: 0.6,
        },
      },
    },

    content: {
      width: "100%",
    },
    stakingContent: {
      paddingTop: 24,
    },

    buttonArea: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: 20,
      marginBottom: 20,

      [theme.breakpoints.down("sm")]: {
        flexWrap: "wrap",
      },
    },

    title: {
      fontWeight: 600,
      margin: "4px 0",
      fontSize: "16px",
      lineHeight: "19px",
      color: "#000B60",
    },

    contentUnderTitle: {
      fontSize: "16px",
      lineHeight: "19px",
      color: "#000B60",
    },

    stakingInfo: {
      display: "flex",

      [theme.breakpoints.down("sm")]: {
        display: "revert",
      },
    },
    stakingOldSC: {
      paddingBottom: 32,
    },

    walletBalance: {
      width: "100%",
      borderRadius: "8px",
      [theme.breakpoints.down("sm")]: {
        width: "auto",
      },
    },
    walletBalanceGroup: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      marginTop: 20,

      "&>div": {
        marginTop: 6,
        display: "flex",
        justifyContent: "space-between",
        font: "14px/24px ",
      },
      "& .token-title": {
        font: "normal normal 500 14px/18px ",
        marginBottom: 2,
      },
    },
    textGray: {
      color: "#AEAEAE",
    },

    tableHead: {
      font: "normal normal 500 14px/18px ",
      padding: "0 28px 0 10px",
      fontSize: 14,
      lineHeight: "18px",
      color: "#AEAEAE",
      background: "none",

      [theme.breakpoints.down("sm")]: {
        padding: "16px 22px",
      },

      "& .group": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",

        "& span": {
          width: "25%",
          textAlign: "right",

          "&:first-child": {
            textAlign: "left",
          },
        },
      },
    },

    tableBody: {
      color: "#fff",

      "& .group": {
        display: "flex",
        justifyContent: "space-between",
        minHeight: 36,
        alignItems: "center",
        padding: "6px 28px 6px 12px",
        background: "#2F2F2F",
        marginTop: 6,
        borderRadius: 4,
        font: "14px/20px ",

        [theme.breakpoints.down("sm")]: {
          padding: "12px 22px",
        },
      },

      "& .group span": {
        width: "25%",
        wordBreak: "break-all",
        font: "14px/20px ",
        color: "#fff",
        textAlign: "right",

        "&:first-child": {
          textAlign: "left",
        },
      },
    },
    balance: {
      fontFamily: "",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "28px",
      lineHeight: "32px",
      color: "#FFFFFF",
      marginTop: "8px",
      marginBottom: "13px",
    },

    warning: {
      // marginBottom: 32,
      padding: "20px 0 0",

      "& div": {
        display: "flex",
        padding: "8px 12px",
        background: "rgb(208, 31, 54, 0.15)",
        borderRadius: 4,
      },

      "& img": {
        width: 16,
        height: 16,
        marginRight: 10,
        marginTop: 3,
      },

      "& span": {
        fontFamily: "",
        fontSize: 14,
        lineHeight: "24px",
      },
    },

    lineBottom: {
      marginBottom: 32,
      borderBottom: "1px solid rgb(255, 255, 255, 0.1)",
    },

    learnMoreTitle: {
      paddingTop: 20,
      marginTop: 30,
      borderTop: "1px solid rgb(255, 255, 255, 0.1)",

      "& div": {
        marginBottom: 8,
        fontSize: 14,
        lineHeight: "24px",
        fontFamily: "",
        color: "#FFFFFF",
      },
    },

    listQuestions: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",

      [theme.breakpoints.down("sm")]: {
        gridTemplateColumns: "1fr",
      },
    },

    itemQuestions: {
      minWidth: "220px",
      fontFamily: "",
      fontSize: 14,
      lineHeight: "24px",
      marginBottom: 4,
      paddingRight: 5,

      "& a": {
        color: "#FFFFFF",
        "&:hover": {
          color: "#6788FF",

          '& span[data-role="title"]': {
            textDecorationLine: "underline",
          },
        },

        '& span[data-role="dot"]': {
          marginRight: "6px",
        },
      },
    },
    lineLearMore: {
      borderTop: "1px solid rgba(255, 255, 255, 0.1)",
      marginBottom: -12,
      marginTop: 32,
    },
  };
});

export default useStyles;
