import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    button: {
      padding: "10px 40px 12px",
      borderRadius: 60,
      background: "#0058FF",
      fontSize: "12px",
      lineHeight: "14px",
      fontWeight: 600,
      color: "#FFFFFF",

      "&:disabled": {
        // backgroundColor: '#b5b5b5 !important' as any
        cursor: "not-allowed",
        opacity: 0.6,
      },
    },
  };
});

export default useStyles;
