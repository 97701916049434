import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  const titleFont = "normal normal 400 14px/18px ";
  return {
    tabTierBenefits: {
      marginTop: 12,
      padding: "20px",
      background: "#D5E3FF",
      borderRadius: "12px",
      overflow: "hidden",
      "& .MuiPaper-elevation1": {
        boxShadow: "none",
      },
    },
    table: {
      "& .MuiTableBody-root td": {
        font: "14px/24px ",
      },
      borderCollapse: "separate !important" as any,
      borderSpacing: "0 6px !important" as any,
      border: "none",
      boxShadow: "none",
      "& .MuiTableCell-alignRight, & .MuiTableCell-head": {
        fontWeight: 600,
      },
    },

    tableContainer: {
      width: "100%",
      background: "transparent",
      "& th, & td": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#000B60",
        background: "#EEF6FF",
        padding: "6px 10px",
        borderTop: "none",
        borderRadius: "4px",
        borderBottom: "none",
      },
      "& th:first-child": {
        borderTopLeftRadius: 2,
        borderBottomLeftRadius: 2,
        maxWidth: 180,
      },
      "& th:last-child": {
        borderTopRightRadius: 2,
        borderBottomRightRadius: 2,
      },
      "& th": {
        [theme?.breakpoints?.down("sm")]: {
          fontSize: 14,
          lineHeight: "20px",
          textAlign: "center",
        },
      },

      "& .MuiTableRow-head th, & td": {
        border: 0,
        background: "transparent",
      },

      "& .status_pool": {
        whiteSpace: "nowrap",
      },
      "& .canceled-whitelist": {
        color: "#D01F36",
      },
      "& .applied-whitelist": {
        color: "#B073FF",
      },
      "& .win-whitelist": {
        color: "#5EFF8B",
      },
      "& .not-win-whitelist": {
        color: "#727272",
      },
      "& .swapping": {
        color: "#6788FF",
      },
      "& .filled": {
        color: "#FFD058",
      },
      "& .claimable": {
        color: "#FFD058",
      },
      "& .completed": {
        color: "#727272",
      },
      "& .none": {
        color: "#FFFFFF",
      },
      [theme?.breakpoints?.down("sm")]: {
        "& h2": {
          fontSize: 16,
          lineHeight: "19px",
        },
      },
    },

    tableHeaderWrapper: {
      "& th": {
        font: titleFont,
        color: "#000B60",
        padding: "0 10px",
        fontSize: "16px",
        lineHeight: "19px",
      },

      // "& tr th:first-child": {
      //   width: 100,
      // },
      "& tr": {
        background: "transparent",
      },
    },

    tierHeader: {
      "& img": {
        width: 24,
      },
    },

    tableLoading: {
      height: 150,
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      background: "transparent",
    },

    tierBenefitsMobile: {},

    itemTierMobile: {
      marginBottom: 30,
      fontSize: 14,
      lineHeight: "20px",
      color: "#FFFFFF",
    },

    nameTierMobile: {
      font: titleFont,
      marginBottom: 6,
    },

    listActiveTierMobile: {},

    valueActiveMobile: {
      display: "flex",
      alignItems: "flex-start",
      fontFamily: "",
      fontWeight: "normal",
      marginBottom: 6,

      "& img": {
        marginRight: 6,
        marginTop: 4,
      },

      "& span": {
        font: titleFont,
        color: "#D01F36",
      },
    },
  };
});

export default useStyles;
