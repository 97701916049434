import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    poolDetailClaim: {
      font: "14px/24px ",
      color: "#000B60",
      display: "grid",
      gap: "20px",
      gridTemplateColumns: "1fr 1fr",

      "& .button-claim": {
        display: "flex",
        justifyContent: "center",
      },

      "& button": {
        padding: "10px 40px 12px",
        borderRadius: 60,
        background: "#0058FF",
        fontSize: "12px",
        lineHeight: "14px",
        fontWeight: 600,
        color: "#FFFFFF",
        border: "none",
        "&:disabled": {
          cursor: "not-allowed",
          opacity: 0.6,
        },
      },

      [theme.breakpoints.down("sm")]: {
        // padding: "36px 20px",
        gridTemplateColumns: "1fr",

        "& #countdown": {
          marginTop: 30,
        },

        "& ul": {
          textAlign: "center",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "space-between",
        },

        "& button": {
          width: "100% !important",
          padding: "0 60px",
          height: "42px",
          font: "normal normal bold 14px/18px ",
        },
      },
    },
    subTitle: {
      color: "#D01F36",
      font: "12px/16px ",
    },
    poolDetailFlex: {
      display: "flex",
      flexDirection: "column",
    },
    poolDetailClaimTitle: {
      padding: "0 0 0 20px",
      fontSize: "24px",
      lineHeight: "24px",
      fontWeight: 600,
      color: "#000B60",
      marginBottom: 12,
      marginRight: "auto",
      textTransform: "uppercase",

      "& .sub-title": {
        font: "12px/16px ",
        color: "#AEAEAE",
        textTransform: "none",
        marginLeft: 2,
      },

      [theme?.breakpoints?.down("sm")]: {
        textAlign: "center",
        fontSize: 16,
        lineHeight: "24px",
      },
    },
    poolDetailInfoItem: {
      display: "flex",
      flexDirection: "column",
      height: "250px",
      padding: "12px 20px",
      background: "#D5E3FF",
      borderRadius: 12,

      [theme?.breakpoints?.down("sm")]: {
        height: "100%",
      },
    },
    colorRed: {
      color: "#D01F36",
      fontWeight: 700,
    },
    tooltip: {
      font: "normal normal 500 14px/24px ",
      color: "#FFFFFF",
      padding: "5px 10px",
      background: "#575757",
    },
    claimTokenContent: {
      textAlign: "right",
    },
    walletField: {
      display: "flex",
      alignItems: "center",
      marginLeft: "auto",
    },
    poolDetailClaimFlex: {
      flex: 1,
    },
    poolDetailClaimInfo: {
      display: "grid",
      gap: "8px",
      marginBottom: 20,
      "& .chain-icon": {
        width: 16,
        height: 16,
        marginRight: 6,
      },
    },
    claimToken: {
      display: "flex",
      flexDirection: "column",
      [theme?.breakpoints?.down("sm")]: {
        paddingRight: 0,
        marginBottom: 30,
      },
    },
    schedule: {},
    scheduleHeader: {
      display: "flex",
    },
    schedulePagination: {
      marginTop: -8,
      "& input": {
        cursor: "pointer",
        width: 28,
        height: 28,
        borderRadius: 2,
        color: "#fff",
        // background: "#2E2E2E",
        border: 0,
        marginLeft: 8,
        "&:hover": {
          opacity: "0.8",
        },
        "&:focus": {
          outline: "none",
        },
        "&:disabled": {
          cursor: "not-allowed",
          opacity: 0.5,
        },
      },
    },
    btnPrevious: {
      background: "url('/images/icons/prev.svg') no-repeat center",
    },
    btnNext: {
      background: "url('/images/icons/next.svg') no-repeat center",
    },

    table: {
      "& .MuiTableBody-root td": {
        font: "normal normal normal 14px/24px ",
      },
      borderCollapse: "separate !important" as any,
      borderSpacing: "0 6px !important" as any,
    },

    tableContainer: {
      width: "100%",
      background: "transparent",
      boxShadow: "none",

      "& th, & td": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#000B60",
        padding: "6px 10px",
      },
      "& th:first-child": {
        maxWidth: 80,
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
      },
      "& th:last-child": {
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
      },

      "& th": {
        border: 0,
        [theme?.breakpoints?.down("sm")]: {
          fontSize: 14,
          lineHeight: "20px",
        },
      },

      "& tr": {
        border: 0,
        background: "#F2F6FE",
      },
    },

    tableHeaderWrapper: {
      "& th": {
        fontSize: "16px",
        lineHeight: "19px",
        fontWeight: 600,
        color: "#000B60",
        padding: "0 10px",
      },

      // "& tr th:first-child": {
      //   width: 100,
      // },
      "& tr": {
        background: "transparent",
      },
    },
    stepToClaim: {
      display: "flex",
      flexDirection: "column",
      "& a": {
        color: "#D01F36",
        textDecoration: "underline",
      },
    },

    poolDetailClaimInfoBlock: {
      display: "grid",
      gridTemplateColumns: "140px 2fr",
      fontSize: "16px",
      lineHeight: "19px",
      color: "#000B60",
      gridColumnGap: 8,

      "& span:last-child": {
        fontWeight: 600,
      },

      // "& .text-blue": {
      //   color: "#6398FF",
      // },
    },

    poolDetailClaimProgress: {
      display: "flex",
      position: "relative",

      "&:before": {
        content: '""',
        position: "absolute",
        top: -12,
        left: 0,
        right: 0,
        height: 6,
        background: "#44454B",

        [theme.breakpoints.down("sm")]: {
          top: 0,
          left: 6,
          right: "auto",
          height: "100%",
          width: 5,
        },
      },

      "& li .mark": {
        position: "absolute",
        top: -17,
        left: 0,
        width: 16,
        height: 16,
        borderRadius: "50%",
        background: "#44454B",
        zIndex: 2,

        [theme.breakpoints.down("sm")]: {
          top: 0,
          left: 0,
          right: "auto",
        },
      },

      "& li .info": {
        fontSize: 12,
        lineHeight: "18px",
        marginTop: 12,

        [theme.breakpoints.down("sm")]: {
          textAlign: "left",
          marginTop: 0,
        },

        "& > div:nth-child(2)": {
          color: "#AEAEAE",
          lineHeight: "20px",
          marginTop: 4,
        },

        "&.show": {
          whiteSpace: "nowrap",
          transform: "translateX(50%) !important",
        },
      },

      "& .first-item": {
        flex: "3 1 0",
        color: "white",

        [theme.breakpoints.down("sm")]: {
          position: "relative",
          width: "100%",
          textAlign: "left",
          paddingLeft: 25,
          flex: "0 0 20px",
        },

        "&.active": {
          color: "#6398FF",
          position: "relative",

          "&:before": {
            content: '""',
            position: "absolute",
            top: -12,
            left: 0,
            right: 0,
            height: 6,
            background: "#6398FF",
            zIndex: 1,

            [theme.breakpoints.down("sm")]: {
              content: "unset",
            },
          },

          "& .info > div:first-child": {
            color: "#6398FF",
          },

          "&.solo:before": {
            [theme.breakpoints.down("sm")]: {
              top: -12,
              left: 6,
              height: "99%",
              width: 5,
              right: "auto",
            },
          },
        },
      },

      "& .item.last-item": {
        flex: "5 1 0",

        "& .info.show": {
          transform: "none !important",
        },

        [theme.breakpoints.down("sm")]: {
          flex: "0 0 80px",
        },
      },

      "& .item": {
        flex: "2 1 0",
        position: "relative",

        [theme.breakpoints.down("sm")]: {
          width: "100%",
          paddingLeft: 25,
          flex: "0 0 50px",
        },

        "& .mark": {
          left: "unset",
          right: 0,

          [theme.breakpoints.down("sm")]: {
            bottom: 0,
            left: 0,
            right: "auto",
            top: "auto",
          },
        },

        "& .info": {
          textAlign: "right",

          [theme.breakpoints.down("sm")]: {
            textAlign: "left",
            position: "absolute",
            left: 24,
            bottom: -24,
          },
        },

        "&:not(:last-child):not(:first-child) .info": {
          position: "absolute",
          right: 0,
          textAlign: "center",
          transform: "translateX(4px)",

          [theme.breakpoints.down("sm")]: {
            textAlign: "left",
            transform: "none",
          },
        },

        "&.active": {
          "&:before": {
            content: '""',
            position: "absolute",
            top: -12,
            left: 0,
            right: 1,
            height: 6,
            background: "#6398FF",
            zIndex: 1,

            [theme.breakpoints.down("sm")]: {
              top: -30,
              left: 6,
              height: "125%",
              width: 5,
              right: "auto",
            },
          },

          "& .info > div:first-child": {
            color: "#6398FF",
          },

          "&.solo:before": {
            [theme.breakpoints.down("sm")]: {
              top: -12,
              left: 6,
              height: "99%",
              width: 5,
              right: "auto",
            },
          },
        },
      },
    },

    poolDetailRefund: {
      marginTop: 20,
      display: "flex",
      flexDirection: "column",
    },
    refundDetail: {
      padding: "16px 20px",
      borderRadius: 8,
      background: "#D5E3FF",
      fontSize: "16px",
      lineHeight: "19px",
      color: "#000B60",
      [theme.breakpoints.down("sm")]: {
        padding: "20px 16px",
      },
    },

    refundPolicy: {
      marginTop: 8,
      display: "flex",
      alignItems: "center",
      fontSize: "16px",
      lineHeight: "19px",
      color: "#000B60",
      "& a": {
        textDecoration: "underline",
        color: "#0058FF",
        marginLeft: 4,
        fontWeight: 600,
      },
    },
    nonRefundable: {
      display: "flex",
      alignItems: "center",
      "& img": {
        width: 14,
        height: 14,
        marginRight: 6,
      },
    },
    refundable: {
      display: "flex",
      flexDirection: "column",
    },
    refundRequest: {
      display: "flex",
      flexDirection: "column",
    },
    requestTextContent: {
      display: "flex",
      justifyContent: "space-between",
      "& p": {
        maxWidth: 520,
        marginRight: 35,
        color: "#000B60",
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: 8,
      },
    },
    btnClaim: {
      [theme.breakpoints.down("sm")]: {
        marginTop: 20,
        width: "100%",
      },
    },
    btnRefund: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    btnRefundXs: {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
    },

    btnRefundContainer: {
      marginTop: 5,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      outline: "none",
      width: "max-content",
      height: 45,
      padding: "16px 40px 18px",
      color: "#fff",
      cursor: "pointer",
      fontSize: "16px",
      lineHeight: "19px",
      transition: "0.25s all",
      background: "#0058FF",
      border: "none",
      borderRadius: 60,
      "&:hover": {
        opacity: "0.85",
      },
      "&:disabled": {
        opacity: "0.4",
        cursor: "not-allowed",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: 36,
      },
    },
    btnRefundSM: {
      height: 28,
    },
    requestDeadlineContainer: {
      "& i": {
        color: "#000B60",
        fontStyle: "normal",
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: 20,
      },
    },
    requestDeadline: {
      color: "#0058FF",
      fontWeight: 600,
    },
    requestDeadlineEmpty: {
      color: "#0058FF",
      fontWeight: 500,
      fontStyle: "normal",
    },
    refundStatus: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      borderTop: "1px solid #A5CCFF",
      paddingTop: 8,
      marginTop: 16,

      "& .label": {
        color: "#000B60",
        fontSize: "16px",
        lineHeight: "19px",
      },
      "& .status": {
        fontSize: "16px",
        lineHeight: "19px",
        fontWeight: 600,
        margin: "0 14px 0 20px",
        "&-requested": {
          color: "#6A00F0",
        },
        "&-refunded": {
          color: "#00A82F",
        },
        "&-not-request": {
          color: "#000B60",
        },
      },

      [theme.breakpoints.down("sm")]: {
        marginTop: 10,
        "& .status": {
          marginLeft: "auto",
          marginRight: 0,
        },
      },
    },
  };
});

export default useStyles;
