import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return {
    main: {
      padding: "80px 20px 120px",
      background: "#ffffff",
      [theme.breakpoints.down("sm")]: {
        padding: "40px 20px 50px",
      },
    },
    container: {
      maxWidth: "1280px",
      margin: "0 auto",
    },
    borderGradient: {
      padding: "1px",
      borderRadius: "32px",
      overflow: "hidden",
      background: "linear-gradient(90deg, #A5CCFF 0%, #E7F2FF 100%)",
    },
    contentBorderGradient: {
      padding: "17px 40px 70px",
      borderRadius: "31px",
      background: "linear-gradient(235.66deg, #ECF5FF 29.71%, #FFFFFF 118.81%)",
      "& h2": {
        fontSize: "48px",
        lineHeight: "48px",
        fontWeight: 500,
        color: "#0055D6",
        textAlign: "center",
      },
      [theme.breakpoints.down("md")]: {
        padding: "20px 20px 40px",
        "& h2": {
          fontSize: "40px",
          lineHeight: "40px",
          fontWeight: 600,
        },
      },
      [theme.breakpoints.down("sm")]: {
        "& h2": {
          fontSize: "30px",
          lineHeight: "30px",
          fontWeight: 600,
        },
      },
    },
    launchContent: {
      display: "grid",
      gap: "44px",
      gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
      paddingTop: "35px",
      alignItems: "center",
      "& img": {
        minWidth: "53px",
      },
      [theme.breakpoints.down("md")]: {
        gap: "20px",
      },
      [theme.breakpoints.down("md")]: {
        gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
      },
    },
    launchContentChild: {
      display: "flex",
      gap: "12px",
      "& h6": {
        fontSize: "24px",
        lineHeight: "24px",
        fontWeight: 600,
        color: "#0055D6",
      },
      "& p": {
        paddingTop: "8px",
        fontSize: "16px",
        lineHeight: "19px",
        color: "#000000",
      },
      [theme.breakpoints.down("md")]: {
        gap: "10px",
        "& h6": {
          fontSize: "20px",
          lineHeight: "20px",
        },
      },
    },
  };
});

export default useStyles;
