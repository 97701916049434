// @ts-nocheck
import {
  Button,
  FormControl,
  Hidden,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withWidth,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Pagination from "@material-ui/lab/Pagination";
import PaginationItem from "@material-ui/lab/PaginationItem";
import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, withRouter } from "react-router-dom";
import LoadingTable from "../../../components/Base/LoadingTable";
import {
  NULL_AMOUNT,
  POOL_IS_PRIVATE,
  POOL_STATUS_JOINED,
} from "../../../constants";
import useAuth from "../../../hooks/useAuth";
import useFetch from "../../../hooks/useFetch";
import useWalletSignature from "../../../hooks/useWalletSignature";
import axios from "../../../services/axios";
import { alertFailure, alertSuccess } from "../../../store/actions/alert";
import { getAccessPoolText } from "../../../utils/campaign";
import { fillClaimInfo } from "../../../utils/claim";
import { unixTimeNow } from "../../../utils/convertDate";
import { getAppNetWork } from "../../../utils/network";
import { getIconCurrencyUsdt } from "../../../utils/usdt";
import ClaimButtonCell from "./ClaimButtonCell";
import ClaimStatusTextCell from "./ClaimStatusTextCell";
import ModalWhitelistCancel from "./ModalWhitelistCancel";
import useStyles from "./style";

const listStatuses = [
  { value: "", babel: "All Statuses", color: "" },
  {
    value: POOL_STATUS_JOINED.APPLIED_WHITELIST,
    babel: "Registered Interest",
    color: "#9E63FF",
  },
  {
    value: POOL_STATUS_JOINED.WIN_WHITELIST,
    babel: "Win Interest",
    color: "#FF9330",
  },
  {
    value: POOL_STATUS_JOINED.NOT_WIN_WHITELIST,
    babel: "Not Win Interest",
    color: "#7E7E7E",
  },
  { value: POOL_STATUS_JOINED.SWAPPING, babel: "Swapping", color: "#6398FF" },
  { value: POOL_STATUS_JOINED.CLAIMABLE, babel: "Claimable", color: "#FFD058" },
  { value: POOL_STATUS_JOINED.COMPLETED, babel: "Completed", color: "#7E7E7E" },
  // {
  //   value: POOL_STATUS_JOINED.CANCELED_WHITELIST,
  //   babel: "Canceled Whitelist",
  //   color: "#D01F36",
  // },
];

const listTypes = [
  { value: 1000, babel: "All types" },
  { value: POOL_IS_PRIVATE.PUBLIC, babel: "Public" },
  { value: POOL_IS_PRIVATE.PRIVATE, babel: "Private" },
  { value: POOL_IS_PRIVATE.SEED, babel: "Seed" },
  { value: POOL_IS_PRIVATE.COMMUNITY, babel: "Community" },
  { value: POOL_IS_PRIVATE.EVENT, babel: "Event" },
];

const MyPools = (props: any) => {
  const styles = useStyles();

  const history = useHistory();
  const dispatch = useDispatch();
  const { account } = useWeb3React();
  const { signature, signMessage } = useWalletSignature();

  // For Detech Claim
  const { connectedAccount, wrongChain } = useAuth();
  const { data: appChain } = useSelector((state: any) => state.appNetwork);
  const appChainID = appChain.appChainID;
  const { data: connector } = useSelector((state: any) => state.connector);
  const appNetwork = getAppNetWork(appChainID);

  const { data: userTier = 0 } = useSelector((state: any) => state.userTier);
  const [input, setInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pools, setPools] = useState([]);
  const [poolCancel, setPoolCancel] = useState({});
  const [openModalCancel, setOpenModalCancel] = useState(false);
  const [currentTimeGetPool, setCurrentTimeGetPool] = useState(
    new Date().getTime()
  );
  const [loadingClaimInfo, setLoadingClaimInfo] = useState(false);
  const now = unixTimeNow();

  const [filterStatus, setFilterStatus] = React.useState<{
    status: string | number;
    babel: string;
  }>({
    status: "",
    babel: "",
  });

  const [filterType, setFilterType] = useState<{
    type: string | number;
    babel: string;
  }>({
    type: 1000,
    babel: "",
  });

  const handleChangeStatus = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const name = event.target.name as keyof typeof filterStatus;
    const value = event.target.value as keyof typeof filterStatus;
    setFilterStatus({
      ...filterStatus,
      [name]: value,
    });
  };

  const handleChangeType = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const name = event.target.name as keyof typeof filterType;
    const value = event.target.value as keyof typeof filterType;
    setFilterType({
      ...filterType,
      [name]: value,
    });
  };

  const getPoolsPrefixUri = () => {
    let uri = "/pools";
    return `${uri}/user/joined-pools`;
  };

  const { data: poolsList, loading: loadingGetPool } = useFetch<any>(
    `${getPoolsPrefixUri()}?page=${currentPage}&limit=10&title=${input}&type=${
      filterType.type
    }&status=${filterStatus.status}&current_time=${currentTimeGetPool}`
  );

  const handleInputChange = debounce((e: any) => {
    Promise.resolve().then(() => {
      setInput(e.target.value);
      setCurrentPage(1);
    });
  }, 500);

  useEffect(() => {
    if (!poolsList?.data) return;
    const manipulatePoolsData = async (pools: any) => {
      let listData = pools.data;
      // Setting Pages:
      setTotalPage(pools.lastPage);
      setCurrentPage(Number(pools.page));
      setLoadingClaimInfo(true);

      if (listData && listData.length > 0) {
        listData = await fillClaimInfo({
          listData,
          connectedAccount,
          connector,
          appChainID,
          appNetwork,
          wrongChain,
        });
      }

      setPools(listData);
      setLoadingClaimInfo(false);
    };

    manipulatePoolsData(poolsList);
  }, [poolsList, appChain, connector]);

  const poolStatus = (pool: any) => {
    if (pool.campaign_status === "Filled") {
      return (
        <div className="status_pool claimable">
          <span>Filled</span>
        </div>
      );
    }
    switch (pool.joined_status) {
      case POOL_STATUS_JOINED.CANCELED_WHITELIST:
        return (
          <div className="status_pool canceled-whitelist">
            <span>Canceled Whitelist</span>
          </div>
        );
      case POOL_STATUS_JOINED.APPLIED_WHITELIST:
        return (
          <div className="status_pool applied-whitelist">
            <span>Registered Interest</span>
          </div>
        );
      case POOL_STATUS_JOINED.WIN_WHITELIST:
        return (
          <div className="status_pool win-whitelist">
            <span>Win Interest</span>
          </div>
        );
      case POOL_STATUS_JOINED.NOT_WIN_WHITELIST:
        return (
          <div className="status_pool not-win-whitelist">
            <span>Not Win Interest</span>
          </div>
        );
      case POOL_STATUS_JOINED.SWAPPING:
        return (
          <div className="status_pool swapping">
            <span>Swapping</span>
          </div>
        );
      case POOL_STATUS_JOINED.CLAIMABLE:
        return <ClaimStatusTextCell poolDetails={pool} />;
      case POOL_STATUS_JOINED.COMPLETED:
        return (
          <div className="status_pool completed">
            <span>Completed</span>
          </div>
        );
      default:
        return (
          <div className="status_pool none">
            <span>-</span>
          </div>
        );
    }
  };

  const allocationAmount = (pool: any) => {
    if (!pool) return null;

    // Get Currency Info
    const { currencyName } = getIconCurrencyUsdt({
      purchasableCurrency: pool?.purchasableCurrency || pool?.accept_currency,
      networkAvailable: pool?.networkAvailable || pool?.network_available,
    });
    if (pool.allowcation_amount === NULL_AMOUNT) return "-";
    let allowcationAmount = pool.allowcation_amount;
    if (new BigNumber(allowcationAmount).lte(0)) return "-";

    const allowcationAmountText = `${new BigNumber(
      allowcationAmount || 0
    ).toFixed()} ${currencyName?.toUpperCase()}`;

    return allowcationAmountText;
  };

  const purchasedAmount = (pool: any) => {
    if (!pool?.purchasers?.[0]) return "-";

    let purchasedAmount = pool?.purchasers?.[0]?.purchased_amount;
    if (new BigNumber(purchasedAmount).lte(0)) return "-";

    const purchasedAmountText = `${new BigNumber(purchasedAmount || 0)
      .div(new BigNumber(10).pow(pool && pool.decimals ? pool.decimals : 18))
      .toFixed()} ${pool?.symbol?.toUpperCase()}`;

    return purchasedAmountText;
  };

  const investedAmount = (pool: any) => {
    if (!pool?.purchasers?.[0]) return "-";

    const currencyInfo: any = {
      eth: {
        usdt: {
          decimal: 6,
        },
        usdc: {
          decimal: 6,
        },
      },
      base: {
        usdt: {
          decimal: 6,
        },
        usdc: {
          decimal: 6,
        },
      },
      bsc: {
        usdt: {
          decimal: 18,
        },
        busd: {
          decimal: 18,
        },
        usdc: {
          decimal: 18,
        },
      },
      polygon: {
        usdt: {
          decimal: 6,
        },
        usdc: {
          decimal: 6,
        },
      },
      avalanche: {
        usdt: {
          decimal: 6,
        },
      },
      arbitrum: {
        usdt: {
          decimal: 6,
        },
      },
    };

    const currencyDecimal =
      pool?.accept_currency == "eth"
        ? 18
        : currencyInfo[pool?.network_available][pool?.accept_currency].decimal;

    let investedAmount = pool?.purchasers?.[0]?.invested_amount;
    if (new BigNumber(investedAmount).lte(0)) return "-";

    const investedAmountText = `${new BigNumber(investedAmount || 0)
      .div(new BigNumber(10).pow(currencyDecimal || 18))
      .toFixed()} ${pool?.accept_currency?.toUpperCase()}`;

    return investedAmountText;
  };

  const onShowModalCancel = async (pool: any) => {
    setPoolCancel(pool);
    if (!signature) {
      await signMessage();
    } else {
      setOpenModalCancel(true);
    }
  };
  useEffect(() => {
    if (signature && connectedAccount) {
      setOpenModalCancel(true);
    }
  }, [signature, connectedAccount]);

  const onCloseModalCancel = () => {
    setPoolCancel({});
    setOpenModalCancel(false);
  };

  const onCancelPool = async (pool: any) => {
    if (signature) {
      const config = {
        headers: {
          msgSignature: process.env.REACT_APP_MESSAGE_INVESTOR_SIGNATURE,
        },
      };
      const response = (await axios.post(
        `/user/unjoin-campaign`,
        {
          signature,
          campaign_id: pool?.id,
          wallet_address: account,
        },
        config as any
      )) as any;

      if (response.data) {
        if (response.data.status === 200) {
          getPoolsPrefixUri();
          setPoolCancel({});
          setOpenModalCancel(false);
          setCurrentTimeGetPool(new Date().getTime());
          dispatch(
            alertSuccess(
              "You have successfully cancelled your whitelist application."
            )
          );
        }

        if (response.data.status !== 200) {
          dispatch(alertFailure(response.data.message));
        }
      }
    }
  };

  const actionButton = (pool: any) => {
    if (
      pool.joined_status === POOL_STATUS_JOINED.NOT_WIN_WHITELIST ||
      pool.joined_status === POOL_STATUS_JOINED.CANCELED_WHITELIST ||
      pool.joined_status === POOL_STATUS_JOINED.SWAPPING ||
      pool.joined_status === POOL_STATUS_JOINED.COMPLETED
    )
      return null;

    /*if (pool.joined_status === POOL_STATUS_JOINED.APPLIED_WHITELIST) {
      return (
        <Button
          // disabled={notEth}
          className={`${styles.btnAction} btnCancelWhitelist`}
          onClick={() => onShowModalCancel(pool)}
        >
          Cancel Whitelist
        </Button>
      );
    }*/

    if (pool.joined_status === POOL_STATUS_JOINED.WIN_WHITELIST) {
      if (
        userTier < pool.pre_order_min_tier || // Not enough tier to PreOrder
        !pool.campaign_hash || // Not deploy yet
        !pool.start_pre_order_time || // Not set PreOrder Time in Admin
        now < parseInt(pool.start_pre_order_time) // Not reached to PreOrder Time yet
      )
        return null;

      if (pool.preOrderUsers && pool.preOrderUsers.length > 0) {
        const amountPreOrdered = pool.preOrderUsers[0]?.pivot?.amount || 0;
        if (
          pool.allowcation_amount !== NULL_AMOUNT &&
          new BigNumber(amountPreOrdered).gte(
            new BigNumber(pool.allowcation_amount)
          )
        ) {
          return null;
        }
      }
      return (
        <Button
          // disabled={notEth}
          className={`${styles.btnAction} btnPreOrder`}
          onClick={() => history.push(`/buy-token/${pool.id}`)}
        >
          Pre-Order
        </Button>
      );
    }

    if (pool.joined_status === POOL_STATUS_JOINED.CLAIMABLE) {
      return (
        <ClaimButtonCell
          poolDetails={pool}
          // notEth={notEth}
        />
      );
    }

    return <></>;
  };

  const renderSearchFilter = () => {
    return (
      <div className={styles.headTable}>
        <div className={styles.leftFillter}>
          {/* <FormControl className={styles.formControlSelect}>
            <Select
              className={styles.selectBox}
              native
              IconComponent={ExpandMoreIcon}
              value={filterStatus.status}
              onChange={handleChangeStatus}
              inputProps={{
                name: "status",
              }}
            >
              {listStatuses?.map((item, index) => {
                return (
                  <option value={item.value} key={index}>
                    {item.babel}
                  </option>
                );
              })}
            </Select>
          </FormControl>
          <FormControl className={styles.formControlSelect}>
            <Select
              className={`${styles.selectBox} ${styles.selectBoxType}`}
              native
              IconComponent={ExpandMoreIcon}
              value={filterType.type}
              onChange={handleChangeType}
              inputProps={{
                name: "type",
                id: "list-types",
              }}
            >
              {listTypes?.map((item, index) => {
                return (
                  <option value={item.value} key={index}>
                    {item.babel}
                  </option>
                );
              })}
            </Select>
          </FormControl> */}
        </div>

        <div className={styles.groupSearch}>
          <input
            type="text"
            placeholder="Search by Pool name, Token Symbol"
            onChange={handleInputChange}
          />
          <Button>
            <img src="/images/icons/search.svg" alt="" />
          </Button>
        </div>
      </div>
    );
  };

  const renderTable = () => {
    const renderLoadingTable = () => {
      return (
        <div className={styles.tableLoading}>
          <LoadingTable />
        </div>
      );
    };

    const renderNoResult = () => {
      return (
        <div className={styles.tableLoading}>
          <div>
            <div className="empty-pool">
              <img
                src="/images/account_v3/empty.svg"
                width={80}
                height={80}
                alt="empty"
              />
            </div>
            <p>You have not participated in any pools.</p>
          </div>
        </div>
      );
    };

    return (
      <Hidden smDown>
        <TableContainer component={Paper} className={styles.tableContainer}>
          <Table className={styles.table} aria-label="simple table">
            <TableHead className={styles.tableHeaderWrapper}>
              <TableRow>
                <TableCell>Pool Name</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Purchased</TableCell>
                <TableCell>Invested</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pools.length > 0 &&
                !loadingGetPool &&
                pools.map((row: any, index: number) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      <Link
                        to={`/buy-token/${row?.id}`}
                        className={styles.toDetailPool}
                      >
                        <img
                          className={styles.iconToken}
                          src={row?.token_images}
                          alt=""
                        />
                        <span className={styles.nameToken}>{row?.title}</span>
                      </Link>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {getAccessPoolText(row)}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {purchasedAmount(row)}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {investedAmount(row)}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {loadingGetPool
          ? renderLoadingTable()
          : pools.length === 0 && renderNoResult()}
      </Hidden>
    );
  };

  const renderTableMobile = () => {
    const renderNoResult = () => {
      return (
        <div className={styles.tableLoading}>
          <div>
            <div className="empty-pool">
              <img
                src="/images/account_v3/empty.svg"
                width={80}
                height={80}
                alt="empty"
              />
            </div>
            <p>You have not participated in any pools.</p>
          </div>
        </div>
      );
    };

    return (
      <Hidden mdUp>
        <div className={styles.datasMobile}>
          {pools?.length > 1 &&
            pools?.map((row: any, index: number) => (
              <div key={index} className={styles.boxDataMobile}>
                <div className={styles.titleBoxMobile}>
                  <Link
                    to={`/buy-token/${row?.id}`}
                    className={styles.toDetailPool}
                  >
                    <img
                      className={styles.iconTokenMobile}
                      src={row?.token_images}
                      alt=""
                    />
                    <span className={styles.nameTokenMobile}>{row?.title}</span>
                  </Link>
                </div>

                <div className={styles.boxContentMobile}>
                  <div className={styles.boxMobileItem}>
                    <div>
                      <span className={styles.nameMobile}>Type</span>
                      <span>{getAccessPoolText(row)}</span>
                    </div>
                    <div className={styles.rightItem}>
                      <span className={styles.nameMobile}>Status</span>
                      <span>{poolStatus(row)}</span>
                    </div>
                  </div>
                  <div className={styles.boxMobileItem}>
                    <div>
                      <span className={styles.nameMobile}>Purchased</span>
                      <span>{purchasedAmount(row)}</span>
                    </div>
                    <div className={styles.rightItem}>
                      <span className={styles.nameMobile}>Invested</span>
                      <span>{investedAmount(row)}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
        {loadingGetPool ? (
          <div className={styles.tableLoading}>
            <LoadingTable />
          </div>
        ) : (
          pools.length === 0 && renderNoResult()
        )}
      </Hidden>
    );
  };

  const renderPagination = () => {
    return (
      <div className={styles.pagination}>
        {totalPage > 1 && (
          <Pagination
            count={totalPage}
            className={styles.pagination}
            onChange={(e: any, value: any) => {
              if (!loadingGetPool) {
                setCurrentPage(value);
              }
            }}
            page={currentPage}
            renderItem={(item: any) => (
              <PaginationItem className="pagination-item" {...item} />
            )}
          />
        )}
      </div>
    );
  };

  return (
    <div className={styles.pageMyPools}>
      <div className={styles.botTitle}>
        <div>
          <h2 className={styles.title}>My Pools</h2>
          <div className={styles.des}>
            Here are all pools that you have participated in.
          </div>
        </div>
        {renderSearchFilter()}
      </div>
      {renderTable()}
      {renderTableMobile()}
      {renderPagination()}

      <ModalWhitelistCancel
        poolCancel={poolCancel}
        openModalCancel={openModalCancel}
        onCloseModalCancel={() => onCloseModalCancel()}
        onCancelPool={(pool: any) => onCancelPool(pool)}
      />
    </div>
  );
};

export default withWidth()(withRouter(MyPools));
