import { makeStyles } from "@material-ui/core";

export const useSwitchStyle = makeStyles((theme) => {
  return {
    switchGroup: {
      "& .MuiTypography-body1": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#000B60",
        fontWeight: 400,
      },
      [theme.breakpoints.down("xs")]: {
        margin: "4px 0 4px -16px",
      },
    },
    root: {
      width: 38,
      height: 22,
      padding: 0,
      margin: theme.spacing(1),
      // [theme.breakpoints.down("xs")]: {
      //   marginTop: "20px",
      //   marginBottom: "30px",
      // },
      [theme.breakpoints.up("xs")]: {
        marginLeft: "20px",
      },
    },
    switchBase: {
      padding: 2,
      "&$checked": {
        transform: "translateX(16px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: "#1F5CD0",
          opacity: 1,
          border: "none",
        },
      },
      "&$focusVisible $thumb": {
        color: "#3232DC",
        border: "6px solid #fff",
      },
    },
    thumb: {
      width: 18,
      height: 18,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid #A4A4A4`,
      backgroundColor: "#A4A4A4",
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
  };
});

export const useButtonGroupStyle = makeStyles((theme) => {
  return {
    btnHeaderGroup: {
      display: "flex",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        justifyContent: "space-between",
      },
    },
    group: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: 41,
      background: "#F2F6FE",
      padding: 4,
      borderRadius: "60px",
      overflow: "hidden",
      color: "#000B60",
      "& button:not(:first-child)": {
        marginLeft: 4,
      },
      "& span": {
        font: "14px/24px ",
        height: 28,
      },
      "&:not(:first-child)": {
        marginLeft: 6,
      },
      "& .MuiButtonGroup-groupedHorizontal:not(:last-child)": {
        borderTopRightRadius: "6px",
        borderBottomRightRadius: "6px",
      },
      "& .MuiButtonGroup-groupedHorizontal:not(:first-child)": {
        borderTopLeftRadius: "6px",
        borderBottomLeftRadius: "6px",
      },
      "& .MuiButton-outlined": {
        padding: 0,
      },
      "& button": {
        height: "33px",
        color: "#000B60",
        fontSize: "16px",
        lineHeight: "19px",
        "& .MuiButton-label": {
          paddingBottom: "2px",
          fontWeight: 600,
        },
      },
    },
    btnDurationType: {
      "& button": {
        width: 80,
        [theme.breakpoints.down("xs")]: {
          width: 62,
        },
      },
    },
    btnStakingType: {
      "& button": {
        width: 90,
        [theme.breakpoints.down("xs")]: {
          width: 86,
        },
      },
    },
    btnActive: {
      background: "#1F5CD0",
      color: "#ffffff !important",
      textTransform: "capitalize",
      fontWeight: "bold",
      outline: "none",
      border: "none",
      borderRadius: "60px !important",
      // padding: "5px 27px",
      [theme.breakpoints.down("xs")]: {
        padding: "5px 10px",
      },
      "&:hover": {
        textTransform: "capitalize",
        background: "#0058FF",
        border: "none",
        outline: "none",
        opacity: 0.8,
      },
    },
    btnDisabled: {
      color: "#FFFFFF",
      textTransform: "capitalize",
      outline: "none",
      borderStyle: "none",
      // padding: "5px 15px",
      [theme.breakpoints.down("xs")]: {
        padding: "5px 10px",
      },
      "&:hover": {
        border: "none",
        outline: "none",
        opacity: 0.8,
      },
    },
    controleForm: {
      width: "100%",
      height: "44px !important",
      padding: "0 60px 0 14px !important",
      background: "#F2F6FE !important",
      border: "none !important",
      fontSize: "16px",
      lineHeight: "19px",
      borderRadius: "60px !important",
      color: "#000B60 !important",
    },
    poolTypeSelect: {
      "& .MuiNativeSelect-root": {},
      "& .MuiNativeSelect-icon": {
        color: "#fff",
      },
      "& .MuiNativeSelect-select option": {
        background: "#292929",
        padding: "4px 0",
        height: 40,
      },
      "&.MuiInput-underline:after": {
        border: "0",
      },
    },
  };
});
