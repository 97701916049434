import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    modalRewards: {
      "& .MuiPaper-root": {
        [theme.breakpoints.down("xs")]: {
          margin: "28px",
        },
      },
      "& .modal-content": {
        backgroundColor: "#FFFFFF",
        borderRadius: 28,
        width: "400px",
        padding: "32px 40px 20px",
        [theme.breakpoints.down("xs")]: {
          padding: "40px 20px 32px",
        },
        color: "#000B60",
        "& .icon-reward": {
          width: 60,
          height: 60,
        },
      },
      "& .modal-content__head": {
        textAlign: "center",
        "& .title": {
          fontSize: "32px",
          lineHeight: "32px",
          color: "#000B60",
          fontWeight: 600,
        },
      },
      "& .duration": {
        padding: "0",
        margin: "0",
        textAlign: "center",
        fontSize: "16px",
        lineHeight: "19px",
      },
      "& .confirm": {
        paddingTop: "12px",
        margin: "0",
        textAlign: "center",
        fontSize: "16px",
        lineHeight: "19px",
        fontWeight: 600,
      },
      "& .modal-content__foot": {
        // borderTop: '1px solid #727272',
        padding: "16px 0px 0px",
      },
    },
    btnConfirm: {
      width: "100%",
      padding: "18px 40px 20px",
      fontSize: "16px",
      borderRadius: "60px",
      background: "#0058FF",
      lineHeight: "19px",
      fontWeight: 600,
    },
  };
});

export default useStyles;
