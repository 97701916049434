import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    modalStake: {
      "& .MuiPaper-root": {
        [theme.breakpoints.down("xs")]: {
          margin: "28px",
        },
      },
      "& .subtitle": {
        font: "normal normal 500 14px/18px ",
      },
    },
    notice: {
      font: "normal normal 500 14px/18px ",
      color: "#FFF",
      marginTop: "30px",
    },
    [theme.breakpoints.down("xs")]: {
      modalTransaction: {
        "& .modal-content__body": {
          padding: "0",
        },
        "& .modal-content__foot": {
          gridTemplateColumns: "1fr",
        },
      },
    },
  };
});

export default useStyles;
