import { useEffect, useRef, useState } from "react";
import { NETWORK, NETWORK_TEXT } from "../../../constants";
import { showTotalRaisePrice } from "../../../utils/campaign";
import useStyles from "./style";
import { Link } from "react-router-dom";

const Card = (props: any): JSX.Element => {
  const timeRef = useRef<any>();
  const labelRef = useRef<any>();
  const lineRef = useRef<any>();
  const styles = useStyles();
  const { pool, isUpcoming, refetchData } = props;

  const getTimeCoundown = (durationBuytime: number, currentTime: number) => {
    const timeLeft = (currentTime * 100) / durationBuytime;
    timeRef?.current?.style && (timeRef.current.style.width = `${timeLeft}%`);
    const maxWidth = lineRef?.current?.clientWidth;
    const labelWidth = labelRef?.current?.clientWidth;
    if ((timeLeft / 100) * maxWidth < labelWidth * 0.5) {
      labelRef?.current?.style && (labelRef.current.style.left = 0);
    } else if ((timeLeft / 100) * maxWidth > maxWidth - labelWidth * 0.5) {
      labelRef?.current?.style &&
        (labelRef.current.style.left = `${maxWidth - labelWidth}px`);
    } else {
      labelRef?.current?.style &&
        (labelRef.current.style.left = `${
          (maxWidth * timeLeft) / 100 - labelWidth * 0.5
        }px`);
    }
    labelRef.current.innerText = `${Math.floor(timeLeft)}%`;
  };

  useEffect(() => {
    const startTime = pool?.start_time ? +pool?.start_time : null;
    const endTime = pool?.finish_time ? +pool?.finish_time : null;
    if (
      pool &&
      startTime &&
      endTime &&
      timeRef?.current &&
      labelRef?.current &&
      lineRef?.current
    ) {
      const durationBuyT = endTime - startTime;
      const currentT = new Date().getTime() / 1000 - startTime;
      getTimeCoundown(durationBuyT, currentT);
      if (currentT < durationBuyT) {
        const intervalId = setInterval(() => {
          const durationBuytime = endTime - startTime;
          const currentTime = new Date().getTime() / 1000 - startTime;
          if (currentTime >= durationBuytime) {
            clearInterval(intervalId);
            refetchData && refetchData();
            return;
          }
          getTimeCoundown(durationBuytime, currentTime);
        }, 1000);
        return () => clearInterval(intervalId);
      }
    }
  }, [pool]);

  return (
    <div className={styles.card}>
      <div className={styles.cardContentTop}>
        {!isUpcoming && (
          <div className={`${styles.poolStatusWarning}`}>Live</div>
        )}
        <img
          src={isUpcoming ? "/images/dashboard/upcoming.svg" : pool?.banner}
          className={styles.imgProject}
          alt=""
        />
        {!isUpcoming && (
          <>
            <div className={styles.runTimeTitle}>
              <div ref={labelRef}>0%</div>
            </div>
            <div ref={lineRef} className={styles.runTime}>
              <div ref={timeRef} />
            </div>
          </>
        )}
        <div className={styles.projectInfo}>
          <div className={styles.projectName}>
            <img src={pool?.token_images} width={51} height={52} alt="" />
            <div>
              <h6 className="limit-text-1-line">{pool?.title}</h6>
              <p>{`$${pool?.symbol}`}</p>
            </div>
          </div>
          <div className={styles.projectData}>
            <div>
              <p>Network</p>
              <h6>
                {NETWORK_TEXT?.[pool?.network_available ?? NETWORK.ETHEREUM]}
              </h6>
            </div>
            <div>
              <p>Total Raise</p>
              <h6>
                {isUpcoming
                  ? "TBA"
                  : pool.isTBAPool
                  ? "-"
                  : Number(pool?.token_conversion_rate) > 0
                  ? showTotalRaisePrice(pool)
                  : "TBA"}
              </h6>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.cardContentBot}>
        <div className={styles.cardBotData}>
          <div className={styles.cardBotLeft}>
            <div />
            <p>
              {isUpcoming
                ? ""
                : `${pool?.participant_number || 0}+ Participants`}
            </p>
          </div>
          <div className={`${styles.cardBotRight} ${isUpcoming && "disabled"}`}>
            <Link to={`/buy-token/${pool?.id}`}>
              <button>Swap Now</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );

  //   <div className={`${styles.boxCard}`} onClick={goToPoolDetail}>
  //     <div className={`${styles.card} ${styles.layerContainer}`}>
  //       <div className={styles.layer1}>
  //       <div className={styles.layer2}>
  //       <div className={styles.cardHeader}>
  //         <img src={pool.banner} alt="" />
  //         <div className={styles.listStatus}>
  //           <div className={`${styles.poolStatusWarning}`}>
  //             {getAccessPoolText(pool)}
  //           </div>
  //         </div>
  //       </div>
  //       <div className={styles.cardBody}>
  //         <div className={styles.cardBodyHead}>
  //           <div className="card-content__title">
  //             <Tooltip
  //               classes={{ tooltip: styles.tooltip }}
  //               title={`${pool.title} (${pool.symbol})`}
  //               arrow
  //               placement="top"
  //             >
  //               <p>{pool.title}</p>
  //             </Tooltip>
  //           </div>
  //           <div className={styles.network}>
  //             <img
  //               src={
  //                 NETWORK_SRC_ICON[pool?.network_available ?? NETWORK.ETHEREUM]
  //               }
  //               alt=""
  //             />
  //           </div>
  //         </div>
  //         <ul className="card-content__content">
  //           <li>
  //             <span>Total Raise</span>
  //             <span className="total" style={{fontSize: 13, fontWeight: 'normal'}}>
  //               {pool.isTBAPool ? "-" : Number(pool?.token_conversion_rate) > 0 ? showTotalRaisePrice(pool) : "TBA"}
  //             </span>
  //           </li>
  //           {/* <li>
  //             <span>Registrations</span>
  //             <span className="total" style={{fontSize: 13, fontWeight: 'normal'}}>
  //               {pool.isTBAPool ? "-" : numberWithCommas(pool?.participant_number || 0)}
  //             </span>
  //           </li> */}
  //           <li>
  //             <span>Token Price</span>
  //             <span className="total" style={{fontSize: 13, fontWeight: 'normal'}}>
  //               {pool.isTBAPool ? "-" : Number(pool?.token_conversion_rate) > 0 ? `$${numberWithCommas(pool?.token_conversion_rate,4)}` : "TBA" }
  //             </span>
  //           </li>
  //           <li>
  //             <span>Start date</span>
  //             <span className="total" style={{fontSize: 13, fontWeight: 'normal'}}>
  //               {pool.isTBAPool ? "-" : showStartTime(pool)}
  //             </span>
  //           </li>
  //           <li>
  //             <span>Refund Policy</span>
  //             <span className="total" style={{fontSize: 13, fontWeight: 'normal', width: '60%', textAlign: "right", lineHeight: 1.5}}>
  //               {pool.isTBAPool ? "-" : showRefundTime(pool)}
  //             </span>
  //           </li>
  //           {/* <li>
  //             <span>Rate</span>
  //             <span className="total">
  //               1&nbsp;{tokenDetails?.symbol}
  //               &nbsp;=&nbsp;
  //               {pool?.ether_conversion_rate} &nbsp;{currencyName}
  //             </span>
  //           </li>
  //           <li>
  //             <span>Supported</span>
  //             <span className="total">{currencyName}</span>
  //           </li> */}
  //         </ul>

  //         {displayShort ? (
  //           <div className={styles.btnApplied}>
  //             {displayShort}&nbsp;
  //             <CountdownSort startDate={countDownDate} />
  //           </div>
  //         ) : (
  //           <div
  //             className={styles.btnApplied}
  //             style={{
  //               backgroundColor:
  //                 campaignStatus === PoolStatus.Progress
  //                   ? "#D01F36"
  //                   : "transparent",
  //             }}
  //           >
  //             {[PoolStatus.Progress, PoolStatus.Filled].includes(campaignStatus)
  //               ? campaignStatus === PoolStatus.Progress
  //                 ? "Swap now"
  //                 : "Detail"
  //               : "TBA"}
  //           </div>
  //         )}
  //       </div>
  //       </div>
  //       </div>
  //     </div>
  //   </div>
  // );
};

export default Card;
