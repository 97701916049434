import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    tierInfomation: {
      width: "100%",
      maxWidth: "120px",
      marginTop: 12,
    },

    listRate: {
      fontFamily: "",
      fontSize: 14,
      lineHeight: "24px",
      "& li:not(:first-child)": {
        marginTop: 4,
      },
    },

    textRate: {},

    conversionRate: {
      display: "flex",
      flexDirection: "column",
      maxWidth: "100%",
      minWidth: "120px",
      flexWrap: "wrap",
      height: "100%",

      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },

    group: {
      display: "flex",
      fontWeight: 400,
      fontFamily: "",
      fontSize: 14,
      lineHeight: "24px",
      color: "#FFFFFF",
    },

    title: {
      marginBottom: 12,
      fontFamily: "",
      fontSize: 14,
      lineHeight: "18px",
      color: "#AEAEAE",
      marginRight: 12,
      fontWeight: 600,

      [theme.breakpoints.down("sm")]: {
        marginRight: 0,
        marginBottom: 4,
      },
    },

    lpWarning: {
      background: "rgba(255, 80, 80, 0.6)",
      borderRadius: 4,
      marginTop: 20,
      padding: 12,
      paddingLeft: 0,
      font: "normal normal normal 14px/20px ",
      display: "flex",

      "& img": {
        margin: "4px 6px auto 8px",
      },
    },

    buttonStake: {
      padding: "8px 32px 10px",
      background: "#0058FF",
      borderRadius: "60px",
      fontSize: "12px",
      lineHeight: "14px",
      color: "#FFFFFF",
      fontWeight: 600,
      border: "none",
    },
  };
});

export default useStyles;
