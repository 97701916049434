import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    main: {
      maxWidth: "1320px",
      margin: "0 auto",
      padding: "120px 20px 0",
      "& h2": {
        fontSize: "48px",
        lineHeight: "48px",
        fontWeight: 500,
        color: "#0055D6",
        textAlign: "center",
      },
      [theme.breakpoints.down("md")]: {
        "& h2": {
          fontSize: "40px",
          lineHeight: "40px",
        },
      },
      [theme.breakpoints.down("sm")]: {
        padding: "50px 20px 0",
        "& h2": {
          fontSize: "30px",
          lineHeight: "30px",
        },
      },
    },
    streamAndLine: {
      paddingTop: "84px",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    streamAndLineMobile: {
      display: "none",
      [theme.breakpoints.down("md")]: {
        position: "relative",
        display: "grid",
        gap: "20px",
        margin: "40px 0 20px",
        padding: "0 0 0 20px",
        "&:before": {
          content: "''",
          position: "absolute",
          width: "1px",
          height: "calc(100% - 12px)",
          top: "12px",
          left: "8px",
          background: "#000B60",
        },
      },
    },
    streamContent: {
      display: "grid",
      gridTemplateColumns: "repeat(5, minmax(0, 1fr))",
    },
    streamLine: {
      position: "relative",
      display: "grid",
      padding: "19px 0 19px 15px",
      gridTemplateColumns: "repeat(5, minmax(0, 1fr))",
      overflow: "hidden",
      "& p": {
        width: "1px",
        height: "20px",
        background: "#000B60",
      },
    },
    streamLineL: {
      position: "absolute",
      width: "100%",
      height: "1px",
      top: "50%",
      left: "15px",
      transform: "translateY(-50%)",
      background: "#000B60",
      zIndex: 1,
    },
    streamContentItem: {
      display: "flex",
      "& div:first-child": {
        minWidth: "34px",
      },
      "& h6": {
        fontSize: "24px",
        lineHeight: "24px",
        fontWeight: 600,
        color: "#0055D6",
      },
      "& p": {
        paddingTop: "8px",
        fontSize: "16px",
        lineHeight: "19px",
        color: "#000B60",
      },
      [theme.breakpoints.down("md")]: {
        position: "relative",
        "&:before": {
          content: "''",
          position: "absolute",
          width: "10px",
          height: "1px",
          top: "12px",
          left: "-16px",
          background: "#000B60",
        },
      },
    },
  };
});

export default useStyles;
