import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  const loaderSize = "35px";
  return {
    loadingContainer: {
      color: "white",
      margin: "0 auto",
      "& img": {
        width: 60,
        height: 60,
      },
    },
    loadingTable: {
      height: loaderSize,
      width: loaderSize,
      position: "relative",
      transform: "rotate(45deg)",
      "& .rhombus": {
        height: `calc(${loaderSize} / 7.5)`,
        width: `calc(${loaderSize} / 7.5)`,
        animationDuration: "2000ms",
        top: `calc(${loaderSize} / 2.3077)`,
        left: `calc(${loaderSize} / 2.3077)`,
        backgroundColor: "#ff1d5e",
        position: "absolute",
        animationIterationCount: "infinite",

        "&:nth-child(2n+0)": {
          marginRight: 0,
        },
        "&.child-1": {
          animationName: "$breeding-rhombus-spinner-animation-child-1",
          animationDelay: "calc(100ms * 1)",
        },
        "&.child-2": {
          animationName: "$breeding-rhombus-spinner-animation-child-2",
          animationDelay: "calc(100ms * 2)",
        },
        "&.child-3": {
          animationName: "$breeding-rhombus-spinner-animation-child-3",
          animationDelay: "calc(100ms * 3)",
        },
        "&.child-4": {
          animationName: "$breeding-rhombus-spinner-animation-child-4",
          animationDelay: "calc(100ms * 4)",
        },
        "&.child-5": {
          animationName: "$breeding-rhombus-spinner-animation-child-5",
          animationDelay: "calc(100ms * 5)",
        },
        "&.child-6": {
          animationName: "$breeding-rhombus-spinner-animation-child-6",
          animationDelay: "calc(100ms * 6)",
        },
        "&.child-7": {
          animationName: "$breeding-rhombus-spinner-animation-child-7",
          animationDelay: "calc(100ms * 7)",
        },
        "&.child-8": {
          animationName: "$breeding-rhombus-spinner-animation-child-8",
          animationDelay: "calc(100ms * 8)",
        },
        "&.big": {
          height: `calc(${loaderSize} / 3)`,
          width: `calc(${loaderSize} / 3)`,
          animationDuration: "2000ms",
          top: `calc(${loaderSize} / 3)`,
          left: `calc(${loaderSize} / 3)`,
          backgroundColor: "#ff1d5e",
          animation:
            "$breeding-rhombus-spinner-animation-child-big 2s infinite",
          animationDelay: "0.5s",
        },
      },
    },

    "@keyframes breeding-rhombus-spinner-animation-child-1": {
      "50%": {
        transform: "translate(-325%, -325%)",
      },
    },

    "@keyframes breeding-rhombus-spinner-animation-child-2": {
      "50%": {
        transform: "translate(0, -325%)",
      },
    },

    "@keyframes breeding-rhombus-spinner-animation-child-3": {
      "50%": {
        transform: "translate(325%, -325%)",
      },
    },

    "@keyframes breeding-rhombus-spinner-animation-child-4": {
      "50%": {
        transform: "translate(325%, 0)",
      },
    },

    "@keyframes breeding-rhombus-spinner-animation-child-5": {
      "50%": {
        transform: "translate(325%, 325%)",
      },
    },

    "@keyframes breeding-rhombus-spinner-animation-child-6": {
      "50%": {
        transform: "translate(0, 325%)",
      },
    },

    "@keyframes breeding-rhombus-spinner-animation-child-7": {
      "50%": {
        transform: "translate(-325%, 325%)",
      },
    },

    "@keyframes breeding-rhombus-spinner-animation-child-8": {
      "50%": {
        transform: "translate(-325%, 0)",
      },
    },

    "@keyframes breeding-rhombus-spinner-animation-child-big": {
      "50%": {
        transform: "scale(0.5)",
      },
    },

    // loadingContainer: {
    //   width: "100%",
    //   height: 300,
    //   // display: "flex",
    //   // margin:'0 auto',
    //   // justifyContent: "center",
    //   // alignItems: "center",
    // },
    // loadingTable: {
    //   display: "inline-block",
    //   position: "relative",
    //   background: "transparent",
    //   width: 40,
    //   height: 40,
    //   "& div": {
    //     position: "absolute",
    //     width: 6,
    //     height: 6,
    //     background: "#D01F36",
    //     borderRadius: "50%",
    //     animation: "loading 1s linear infinite",

    //     "&:nth-child(1)": {
    //       animationDelay: "0s",
    //       top: 1,
    //       left: 20,
    //     },
    //     "&:nth-child(2)": {
    //       animationDelay: "-0.1s",
    //       top: 4,
    //       left: 28,
    //     },
    //     "&:nth-child(3)": {
    //       animationDelay: "-0.2s",
    //       top: 12,
    //       left: 35,
    //     },
    //     "&:nth-child(4)": {
    //       animationDelay: "-0.3s",
    //       top: 25,
    //       left: 35,
    //     },
    //     "&:nth-child(5)": {
    //       animationDelay: "-0.4s",
    //       top: 35,
    //       left: 28,
    //     },
    //     "&:nth-child(6)": {
    //       animationDelay: "-0.5s",
    //       top: 39,
    //       left: 20,
    //     },
    //     "&:nth-child(7)": {
    //       animationDelay: "-0.6s",
    //       top: 35,
    //       left: 12,
    //     },
    //     "&:nth-child(8)": {
    //       animationDelay: "-0.7s",
    //       top: 25,
    //       left: 3,
    //     },
    //     "&:nth-child(9)": {
    //       animationDelay: "-0.8s",
    //       top: 12,
    //       left: 3,
    //     },
    //     "&:nth-child(10)": {
    //       animationDelay: "-0.9s",
    //       top: 4,
    //       left: 12,
    //     },
    //   },
    // },

    // "@keyframes loading": {
    //   "100%": {
    //     transform: "scale(1)",
    //   },
    //   "80%": {
    //     transform: "scale(1.2)",
    //   },
    //   "60%": {
    //     transform: "scale(1.4)",
    //   },
    //   "40%": {
    //     transform: "scale(1.6)",
    //   },
    //   "20%": {
    //     transform: "scale(1.8)",
    //   },
    //   "0%": {
    //     transform: "scale(2)",
    //   }
    // }
  };
});

export default useStyles;
