import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    pageMyPools: {
      borderRadius: "12px",
      padding: "23px 40px",

      [theme.breakpoints.down("sm")]: {
        padding: "20px 20px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "10px",
      },
    },

    title: {
      fontSize: "32px",
      lineHeight: "32px",
      fontWeight: 600,
      color: "#000B60",
      marginBottom: 8,

      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
        fontWeight: 500,
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },

    botTitle: {
      display: "flex",
      gap: "4px",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        display: "grid",
        justifyContent: "normal",
      },
    },

    des: {
      fontSize: "16px",
      lineHeight: "19px",
      color: "#000B60",
      marginBottom: 2,
    },

    listDes: {
      fontFamily: "",
      fontSize: 14,
      lineHeight: "20px",
      color: "#AEAEAE",
      listStyle: "disc",
      paddingLeft: 25,
      marginBottom: 30,

      [theme.breakpoints.down("sm")]: {
        marginBottom: 20,
      },
    },

    headTable: {
      width: "100%",
      maxWidth: 320,
      marginBottom: 9,
      display: "flex",
      justifyContent: "space-between",

      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        maxWidth: "100%",
      },
    },

    leftFillter: {
      [theme.breakpoints.down("sm")]: {
        marginBottom: 8,
        display: "flex",
        justifyContent: "space-between",
      },
      [theme.breakpoints.only("xs")]: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: 8,
      },
    },

    formControlSelect: {
      maxWidth: "100%",
      background: "#222228",
      border: "1px solid #44454B",
      borderRadius: 4,
      marginRight: 8,

      [theme.breakpoints.down("sm")]: {
        marginRight: 0,
      },
      [theme.breakpoints.only("xs")]: {
        width: "100%",
        margin: 0,
      },
    },

    selectBox: {
      fontFamily: "",
      fontSize: 14,
      lineHeight: "24px",
      color: "#FFFFFF",
      height: 36,
      width: 160,
      border: "none",
      background: "#292929",

      "&::before, &::after": {
        display: "none",
      },

      "& select": {
        paddingLeft: 12,
        paddingTop: 0,
        paddingBottom: 0,
        height: 22,
      },

      "& .MuiSelect-select option": {
        backgroundColor: "#292929",
      },

      "& .MuiSelect-icon": {
        color: "#FFFFFF",
        fontSize: 20,
        top: "calc(50% - 10px)",
        right: 4,
      },
      [theme.breakpoints.only("xs")]: {
        width: "100% !important" as any,
      },
    },

    selectBoxType: {
      width: 120,
    },

    groupSearch: {
      width: "100%",
      maxWidth: 320,
      background: "#D5E3FF",
      borderRadius: 60,
      display: "flex",

      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",

        "& button": {
          minWidth: 36,
        },
      },

      "& button": {
        minWidth: 36,
      },

      "& input": {
        height: 36,
        width: "100%",
        border: "none",
        outline: "none",
        padding: 12,
        background: "transparent",
        fontFamily: "",
        fontSize: 14,
        lineHeight: "20px",
        color: "#AEAEAE",
      },
    },

    table: {
      "& .MuiTableBody-root td": {
        font: "normal normal normal 14px/24px ",
      },
      borderCollapse: "separate !important" as any,
      borderSpacing: "0 6px !important" as any,
      "& .MuiTableBody-root .MuiTableCell-body": {
        fontSize: "18px",
        lineHeight: "21px",
        textAlign: "right",
        "&:first-child": {
          textAlign: "left",
        },
      },
      "& .MuiTableRow-head .MuiTableCell-head": {
        textAlign: "right",
        "&:first-child": {
          textAlign: "left",
        },
      },
    },

    tableContainer: {
      width: "100%",
      marginTop: "20px",
      background: "transparent",
      boxShadow: "none",

      "& th, & td": {
        padding: "12px 5px",
        fontSize: "16px",
        lineHeight: "19px",
        fontWeight: 600,
        color: "#000B60",
      },
      "& th:first-child": {
        borderTopLeftRadius: 2,
        borderBottomLeftRadius: 2,
        maxWidth: 180,
        padding: "12px 5px 12px 20px",
      },
      "& th:last-child": {
        borderTopRightRadius: 2,
        borderBottomRightRadius: 2,
        padding: "12px 20px 12px 5px",
      },
      "& th": {
        border: 0,
        [theme?.breakpoints?.down("sm")]: {
          fontSize: 14,
          lineHeight: "20px",
        },
      },
      "& tr": {
        border: 0,
        background: "#D5E3FF",
        "& th:first-child": {
          borderTopLeftRadius: 12,
          borderBottomLeftRadius: 12,
        },
        "& th:last-child": {
          borderTopRightRadius: 12,
          borderBottomRightRadius: 12,
        },
      },

      "& .status_pool": {
        whiteSpace: "nowrap",
      },
      "& .canceled-whitelist": {
        color: "#D01F36",
      },
      "& .applied-whitelist": {
        color: "#B073FF",
      },
      "& .win-whitelist": {
        color: "#5EFF8B",
      },
      "& .not-win-whitelist": {
        color: "#727272",
      },
      "& .swapping": {
        color: "#6788FF",
      },
      "& .filled": {
        color: "#FFD058",
      },
      "& .claimable": {
        color: "#FFD058",
      },
      "& .completed": {
        color: "#727272",
      },
      "& .none": {
        color: "#FFFFFF",
      },
    },

    tableHeaderWrapper: {
      "& th": {
        padding: "0 10px",
      },

      // "& tr th:first-child": {
      //   width: 100,
      // },
      "& tr": {
        background: "transparent",
      },
    },

    tableLoading: {
      padding: "40px 0",
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      background: "#D5E3FF",
      borderRadius: "12px",
      "& .empty-pool": {
        display: "flex",
        justifyContent: "center",
      },
      "& p": {
        paddingTop: 20,
        fontSize: "16px",
        lineHeight: "19px",
        color: "#000B60",
      },
    },

    nameToken: {
      fontSize: "18px",
      lineHeight: "21px",
      fontWeight: 600,
      color: "#000B60",
    },

    toDetailPool: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      color: "#FFFFFF",

      "&:hover": {
        opacity: 0.85,
      },
    },

    iconToken: {
      marginRight: 8,
      width: 36,
      minWidth: 36,
      height: 36,
      borderRadius: 4,
    },

    datasMobile: {
      display: "flex",
      flexDirection: "column",
    },

    boxDataMobile: {
      padding: "16px 20px",
      background: "#D5E3FF",
      borderRadius: 12,
      marginBottom: 12,
      "&:last-child": {
        marginBottom: 0,
      },
    },

    titleBoxMobile: {
      display: "flex",
      alignItems: "center",
      marginBottom: 16,
    },

    iconTokenMobile: {
      width: 36,
      height: 36,
      marginRight: 8,
      borderRadius: "50%",
    },

    nameTokenMobile: {
      fontWeight: 600,
      fontSize: 16,
      lineHeight: "24px",
      color: "#000B60",
    },
    boxContentMobile: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      font: "14px/24px ",
      color: "#fff",
    },
    boxMobileItem: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      marginBottom: 12,
      "&:last-child": {
        marginBottom: 0,
      },
      "&>div": {
        display: "flex",
        flexDirection: "column",
        "& span": {
          color: "#000B60",
          "&:last-child": {
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "19px",
          },
        },
      },
    },
    rightItem: {
      textAlign: "right",
    },

    infoMobile: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gridRowGap: 18,
    },

    nameMobile: {
      font: "12px/16px ",
      color: "#AEAEAE",
    },

    valueMobile: {
      fontFamily: "",
      fontSize: 14,
      lineHeight: "20px",
      display: "flex",
      alignItems: "center",
      color: "#FFFFFF",
    },

    pagination: {
      display: "flex",
      justifyContent: "center",
      marginTop: 12,
      "& *": {
        color: "#000B60",
      },
      "& li": {
        "& .pagination-item": {
          fontSize: "16px",
          lineHeight: "19px",
          fontWeight: 600,
          backgroundColor: "transparent !important",
          color: "#000B60",
          "&.Mui-selected": {
            color: "#0058FF",
          },
        },
        "& .MuiPaginationItem-page.Mui-disabled": {
          opacity: 1,
        },
        "& .MuiPaginationItem-page .MuiPaginationItem-icon": {
          fill: "#000B60",
        },
        "&:first-child .pagination-item": {
          backgroundColor: "#FFFFFF !important",
          borderRadius: 6,
          color: "#FFFFFF",
        },
        "&:last-child .pagination-item": {
          backgroundColor: "#FFFFFF !important",
          borderRadius: 6,
          color: "#FFFFFF",
        },
      },
    },

    learnMoreTitle: {
      paddingTop: 20,
      marginTop: 30,
      borderTop: "1px solid rgb(255, 255, 255, 0.1)",

      "& div": {
        marginBottom: 8,
        fontSize: 14,
        lineHeight: "24px",
        fontFamily: "",
        color: "#FFFFFF",
      },
    },

    listQuestions: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",

      [theme.breakpoints.down("sm")]: {
        gridTemplateColumns: "1fr",
      },
    },

    itemQuestions: {
      minWidth: "220px",
      fontFamily: "",
      fontSize: 14,
      lineHeight: "24px",
      marginBottom: 4,
      paddingRight: 5,

      "& a": {
        color: "#FFFFFF",
        "&:hover": {
          color: "#6788FF",

          '& span[data-role="title"]': {
            textDecorationLine: "underline",
          },
        },

        '& span[data-role="dot"]': {
          marginRight: "6px",
        },
      },
    },

    btnAction: {
      background: "#ebebeb",
      borderRadius: 4,
      minWidth: 112,
      height: 28,
      border: "none",
      boxShadow: "none",
      outline: "none",
      fontFamily: "",
      fontWeight: 600,
      fontSize: 12,
      lineHeight: "16px",
      textAlign: "center",
      color: "#000000",
      textTransform: "inherit",

      "&.btnCancelWhitelist": {
        background: "#D01F36",
        color: "#FFFFFF",
        cursor: "pointer",
      },

      "&.btnPreOrder": {
        background: "#FF9330",
        color: "#000000",
        cursor: "pointer",
      },

      "&.btnClaimToken": {
        background: "#FFD058",
        color: "#000000",
        cursor: "pointer",
      },

      "&:disabled": {
        opacity: 0.5,
      },
    },

    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  };
});

export default useStyles;
