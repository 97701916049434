import React from "react";
import { APPLY_TO_LAUNCH_URL } from "../../../constants";
import useStyles from "./styles";

const ApplyToLaunch: React.FC<any> = (props: any) => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <div className={styles.mainBox}>
        <h6>Launch on RWA?</h6>
        <p>Don’t miss the chance and super-charge your IDO launch!</p>

        <a href="https://www.rwa.inc/apply" target="_blank" rel="noreferrer">
          <button>
            Apply for launchpad
            <img
              src="/images/landing/arrow_right.svg"
              width={18}
              height={8}
              alt="arrow"
            />
          </button>
        </a>
      </div>
    </div>
  );
};

export default ApplyToLaunch;
