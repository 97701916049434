import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    dialog: {
      "& .MuiPaper-root": {
        background:
          "linear-gradient(180deg, #79A7FF 0%, rgba(255, 255, 255, 1) 28.5%)",
        borderRadius: "28px",
        padding: "40px",
        maxWidth: 400,
      },
      "& .MuiIconButton-root": {
        width: "28px",
        height: "28px",
        background: "#FFFFFF",
        "& path": {
          fill: "#0058FF",
        },
      },
    },
    walletTitle: {
      display: "grid",
      gap: "12px",
      fontSize: "32px",
      lineHeight: "32px",
      color: "#000B60",
      fontWeight: 600,
      justifyContent: "center",
      "& img": {
        margin: "0 auto",
      },
    },
    dialogContentTypo: {
      color: "#000B60",
      padding: "20px 0 12px",
      margin: "0 !important",
      fontSize: "16px",
      lineHeight: "21px",
      fontWeight: 600,
    },
    dialogTitle: {
      padding: "0 !important",
      textAlign: "center",
      background: "transparent !important",
      font: "normal normal 500 22px/28px ",
      "& .MuiTypography-h6": {
        font: "normal normal bold 28px/32px ",
      },

      "& .MuiSvgIcon-root": {
        fontSize: "1rem",
      },
    },
    dialogPrivacy: {
      display: "flex",
      alignItems: "center",
      paddingBottom: 10,
    },
    dialogPrivacyText: {
      display: "flex",
      flexWrap: "wrap",
      gap: "0 4px",
      padding: "0 !important",
      fontSize: 16,
      lineHeight: "19px",
      color: "#000B60",
    },
    dialogContentText: {
      padding: "12px 0 0 !important",
    },
    dialogPrivacyHighlight: {
      color: "#6788ff",
      fontWeight: "normal",

      "&:hover": {
        color: "#6788ff",
        textDecoration: "underline",
      },
    },
    dialogCheckbox: {
      padding: 0,
      marginRight: 8,

      "& .MuiSvgIcon-root": {
        // fill: 'white'
      },

      "& .MuiIconButton-label": {
        width: "18px",
        height: "18px",
        border: "0.5px solid #0055D64D",
        background: "#F1F6FF",
        borderRadius: "4px",
      },
    },
    dialogNetworks: {
      display: "flex",
      gap: 8,
      flexWrap: "wrap",
    },
    [theme.breakpoints.down("xs")]: {
      dialog: {
        "& .MuiPaper-root": {
          backgroundColor: "#212126",
          borderRadius: "28px",
          border: "none",
          padding: "20px",
          margin: "20px",
          maxWidth: "100%",
          width: "100%",
        },
      },
      dialogNetworks: {
        display: "flex",
        gap: 8,
      },
      dialogContentBlock: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        gap: 8,
        "& .MuiPaper-root": {
          padding: "20px 0",
        },
      },
      dialogPrivacy: {
        display: "flex",
        alignItems: "flex-start",
        gap: 0,
      },
    },
  };
});

export default useStyles;
