import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return {
    main: {
      padding: "120px 20px 0",
      background: "#ffffff",
      [theme.breakpoints.down("sm")]: {
        padding: "50px 20px 0",
      },
    },
    container: {
      maxWidth: "1280px",
      margin: "0 auto",
    },
    borderGradient: {
      display: "flex",
      gap: "5px",
      padding: "38px 15px 23px",
      borderRadius: "32px",
      overflow: "hidden",
      background: "linear-gradient(91.5deg, #ECF5FF 1.28%, #FFFFFF 135.26%)",
      border: "1px solid #A5CCFF",
      [theme.breakpoints.down("md")]: {
        padding: "20px",
      },
      [theme.breakpoints.down("sm")]: {
        display: "grid",
        gap: "20px",
      },
    },
    title: {
      width: "28%",
      padding: "21px 0 0 25px",
      "& h6": {
        fontSize: "48px",
        fontWeight: 500,
        lineHeight: "48px",
        color: "#0055D6",
      },
      "& p": {
        maxWidth: "230px",
        paddingTop: "12px",
        fontSize: "20px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#000000",
      },
      [theme.breakpoints.down("md")]: {
        padding: "0",
        "& h6": {
          fontSize: "30px",
          lineHeight: "30px",
          fontWeight: 600,
        },
        "& p": {
          paddingTop: "8px",
          fontSize: "16px",
          lineHeight: "19px",
        },
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        textAlign: "center",
        "& p": {
          maxWidth: "100%",
        },
      },
    },
    step: {
      display: "grid",
      width: "72%",
      gap: "60px",
      gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
      [theme.breakpoints.down("md")]: {
        gap: "20px",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        gap: "40px",
        gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
        textAlign: "center",
      },
    },
    stepItem: {
      "& h6": {
        paddingTop: "12px",
        fontSize: "24px",
        fontWeight: 600,
        lineHeight: "24px",
        color: "#0055D6",
      },
      "& p": {
        paddingTop: "4px",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "19px",
        color: "#000000",
      },
      "& button": {
        maxHeight: "44px",
        width: "100%",
        maxWidth: "125px",
        marginTop: "26px",
        padding: "15px 25px",
        background: "#0058FF",
        borderRadius: "45px",
        border: "none",
        fontSize: "16px",
        lineHeight: "16px",
        fontWeight: 600,
        color: "#FFFFFF",
        "& div": {
          paddingBottom: "4px",
        },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      [theme.breakpoints.down("md")]: {
        "& h6": {
          paddingTop: "8px",
          fontSize: "20px",
          lineHeight: "20px",
        },
        "& button": {
          marginTop: "20px",
          padding: "8px 20px",
          "& div": {
            paddingBottom: "4px",
          },
        },
      },
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& button": {
          maxWidth: "125px",
        },
        "& div": {
          display: "flex",
          gap: "6px",
          alignsItems: "center",
          justifyContent: "center",
        },
        "& h6": {
          padding: "3px 0 0",
        },
      },
    },
  };
});

export default useStyles;
