import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    sectionBuyTokenPoolTimeLine: {
      borderRadius: 12,
      marginTop: 12,
      color: "#000B60",
      "&>span": {
        fontSize: "16px",
        lineHeight: "19px",
      },
    },

    statusBarSteps: {
      display: "flex",
      position: "relative",
      marginBottom: 24,
      marginTop: 6,
      flexDirection: "column",
      width: "calc(100%)",

      "&:before": {
        height: "calc(100% - 50px)",
        content: '""',
        display: "block",
        position: "absolute",
        background: "#C7D9FD",
        borderRadius: 20,
        width: 2,
        top: 25,
        left: 3,
      },
    },

    itemStatusBarSteps: {
      display: "flex",
      position: "relative",
      color: "#000B60",
      width: "calc(100%)",
    },

    itemValue: {
      width: 8,
      minWidth: "8px",
      height: 8,
      marginTop: 21,
      borderRadius: "50%",
      background: "#C7D9FD",
      cursor: "pointer",
      "&.active": {
        background: "#1F5CD0",
      },
    },

    itemName: {
      textAlign: "center",
      position: "relative",
      marginTop: 0,
      left: "0",
      paddingLeft: 12,
      // },
    },
    statusBar: {
      display: "flex",
      alignItems: "center",
    },
    stepContent: {
      width: "100%",
      marginLeft: 8,
      padding: "14px 16px",
      position: "relative",
      display: "flex",
      flexDirection: "column",

      "& .status-title": {
        fontSize: "16px",
        lineHeight: "19px",
        fontWeight: 600,
        color: "#0058FF",
        textTransform: "uppercase",
        // paddingLeft: 12
      },
      "& .short-date": {
        fontSize: "14px",
        lineHeight: "16px",
        fontWeight: 600,
        color: "#000B60",
        marginLeft: "auto",
      },
      "& .date-title": {
        fontWeight: 400,
      },
      "& .date-period": {
        marginLeft: "auto",
        color: "#000B60",
        fontWeight: "600",
      },
      "& .time-period": {
        fontWeight: "600",
        fontSize: "14px",
        lineHeight: "16px",
        color: "#000B60",
        marginTop: 8,
      },
    },
    stepActive: {
      "&>div": {
        background: "#D5E3FF",
        width: "100%",
        borderRadius: 8,
        padding: "14px 16px",
      },
      "&:last-child>div": {
        top: 0,
      },
    },
    displayShort: {
      fontSize: "14px",
      lineHeight: "16px",
      color: "#000B60",
      marginTop: 2,
    },
    detailContent: {
      fontSize: "14px",
      lineHeight: "16px",
      fontWeight: 600,
      color: "#000B60",
      "& span": {
        marginTop: 12,
      },
    },
    textWhite: {
      color: "#fff",
    },

    countdownTitle: {
      fontSize: "14px",
      lineHeight: "16px",
      color: "#000B60",
      fontWeight: 600,
    },

    erroCountdown: {
      fontFamily: "",
      fontWeight: "bold",
      // fontSize: 20,
      // lineHeight: '24px',
      color: "#FFFFFF",
      // marginTop: 100,
      display: "flex",
      flexWrap: "wrap",

      // [theme?.breakpoints?.down('md')]: {
      marginTop: 30,
      fontSize: 14,
      lineHeight: "18px",
      textAlign: "center",
      justifyContent: "center",
      // },
    },

    customToolTip: {
      width: 280,
      background: "#44454B",
      boxShadow: `0px 12px 20px rgba(0, 0, 0, 0.07)`,
      borderRadius: 4,
      padding: 13,

      // [theme?.breakpoints?.down('sm')]: {
      marginTop: 10,
      // },
    },

    nameToolTip: {
      fontFamily: "",
      fontWeight: "bold",
      fontSize: 14,
      lineHeight: "18px",
      color: "#FFFFFF",
      marginBottom: 4,
    },

    desToolTip: {
      fontFamily: "",
      fontSize: 14,
      lineHeight: "20px",
      color: "#FFFFFF",

      "& span": {
        fontWeight: "bold",
      },
    },
  };
});

export default useStyles;
