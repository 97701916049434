import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    currencyName: {
      fontWeight: 400,
    },

    btnGroup: {
      marginTop: 12,
      paddingTop: 12,
      // borderTop: "1px solid #44454B",
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gridColumnGap: 8,

      "&>div>button": {
        width: "100%",
        padding: "10px 32px 12px",
        fontSize: "12px",
        lineHeight: "14px",
        fontWeight: 600,
        color: "#fff !important",
        background: "transparent",
        border: "1px solid",
        borderRadius: 60,
      },

      "&>div:last-child>button:disabled": {
        opacity: 0.4,
        cursor: "not-allowed",
      },

      "&>div:first-child>button:disabled": {
        opacity: 0.4,
        cursor: "not-allowed",
      },

      [theme?.breakpoints?.down("sm")]: {
        marginTop: 12,
        paddingTop: 32,
        gridTemplateColumns: "1fr",

        "&>div:first-child>button": {
          marginBottom: 12,
        },
      },

      [theme.breakpoints.down("xs")]: {
        "&>div:first-child>button": {
          marginBottom: 0,
        },
      },
    },

    buyTokenForm: {
      fontFamily: "",
      marginBottom: "20px",
      paddingBottom: 20,
      color: "#000B60",
      display: "grid",
      gap: "20px",
      gridTemplateColumns: "1fr 1fr",
      borderBottom: "1px solid #C6DFFF",

      [theme?.breakpoints?.down("sm")]: {
        gridTemplateColumns: "1fr",
        // padding: "28px 20px",
      },
    },

    poolBuyTokenItem: {
      height: "100%",
      padding: "12px 20px",
      background: "#D5E3FF",
      borderRadius: 12,
    },
    leftBuyTokenForm: {
      [theme?.breakpoints?.down("sm")]: {
        marginBottom: 20,
        paddingRight: 0,
      },
    },

    rightBuyTokenForm: {
      wordBreak: "break-word",
      background: "#D5E3FF",
      borderRadius: 12,
      padding: "12px 20px",
    },

    title: {
      font: "normal normal 500 14px/18px ",
      marginBottom: 12,
      textTransform: "uppercase",

      [theme?.breakpoints?.down("sm")]: {
        textAlign: "center",
        fontSize: 16,
        lineHeight: "24px",
      },
    },

    title2: {
      marginBottom: 8,
      fontSize: "16px",
      lineHeight: "19px",
      color: "#000B60",
      display: "flex",
      flexWrap: "wrap",
      fontWeight: 600,
      justifyContent: "space-between",
    },

    buyTokenFormTitle: {
      marginTop: 10,
      lineHeight: "24px",
      color: "#AEAEAE",
      fontFamily: "",
      fontWeight: 500,
      fontSize: 16,

      [theme?.breakpoints?.down("sm")]: {
        fontSize: 14,
        lineHeight: "20px",
        marginTop: 0,
      },
    },

    buyTokenInputForm: {
      background: "#F2F6FE",
      maxWidth: "100%",
      padding: 4,
      paddingLeft: 12,
      borderRadius: 8,
    },

    buyTokenInputWrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: 14,
      lineHeight: "18px",
      height: 32,

      [theme?.breakpoints?.down("sm")]: {
        display: "grid",
        gridTemplateColumns: " auto 125px",
      },

      "& span": {
        fontWeight: "bold",
      },
    },

    buyTokenInput: {
      backgroundColor: "transparent",
      border: "none",
      fontSize: "14px",
      lineHeight: "16px",
      color: "#000B60",

      "&:focus": {
        outline: "none",
      },

      "&::placeholder": {
        fontFamily: "",
        fontSize: 14,
        lineHeight: "20px",
        color: "#AEAEAE",
      },

      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },

    buyTokenInputLabel: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      color: "#999999",
      font: "normal normal normal 12px/18px ",
    },

    buyTokenFee: {
      color: "#999999",
      marginTop: 10,
      font: "normal normal normal 12px/18px ",
    },

    buyTokenEstimate: {
      marginTop: 16,
    },

    buyTokenEstimateLabel: {
      font: "normal normal 500 14px/18px ",
    },

    buyTokenEstimateAmount: {
      fontSize: "24px",
      lineHeight: "24px",
      fontWeight: 600,
      color: "#0058FF",
      marginTop: 2,
    },
    tokenPrice: {
      fontSize: "16px",
      lineHeight: "19px",
      color: "#000B60",
      marginLeft: "auto",
    },
    approcimately: {
      fontSize: "16px",
      lineHeight: "19px",
      color: "#000B60",
    },
    poolErrorBuyWarning: {
      font: "normal normal bold 15px/18px ",
      color: "#fff100",
      display: "none",
    },

    poolErrorBuy: {
      font: "normal normal bold 15px/18px ",
      // marginTop: 25,
      color: "#D01F36",
    },

    purchasableCurrency: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      fontSize: "16px",
      lineHeight: "19px",
      color: "#000B60",
      fontWeight: 600,
    },

    purchasableCurrencyIcon: {
      width: 30,
      height: 30,
      marginRight: 7,
    },

    purchasableCurrencyMax: {
      background: "#1F5CD0",
      borderRadius: 6,
      padding: "6px 16px 8px",
      // minWidth: 60,
      // height: 32,
      border: "none",
      fontSize: "16px",
      lineHeight: "19px",
      color: "#FFFFFF",
      marginLeft: 8,
      "&:hover": {
        opacity: ".9",
      },
      "&:focus": {
        outline: "none",
      },
      "&:active": {
        transform: "translateY(-3px)",
      },
    },

    title3: {
      marginTop: 12,
      fontSize: "16px",
      lineHeight: "19px",
      color: "#000B60",

      [theme.breakpoints.down("sm")]: {
        marginTop: 32,
      },
    },

    allowcationWrap: {
      marginBottom: 6,
      color: "#FFFFFF",
      fontSize: 14,
      display: "flex",
      gridColumnGap: 8,
      justifyContent: "space-between",

      // [theme.breakpoints.down('xs')]: {
      //   gridTemplateColumns: 'minmax(140px, 140px) 1fr',
      // },
    },

    allocationTitle: {
      fontSize: "16px",
      lineHeight: "19px",
      color: "#000B60",
      fontWeight: 400,
    },

    allocationContent: {
      fontSize: "16px",
      lineHeight: "19px",
      color: "#000B60",
      fontWeight: 600,
    },
    captchaContainer: {
      margin: "auto",
      marginTop: 10,
      marginBottom: -10,
      width: "fit-content",
      textAlign: "center",
    },

    [theme?.breakpoints?.only("xs")]: {
      buyTokenForm: {
        borderBottom: "1px solid #44454B",
        paddingTop: 12,
      },
      title: {
        display: "none",
      },
      step: {
        margin: 0,
        marginBottom: 16,
      },
      buyTokenEstimate: {
        marginTop: 6,
        "&>div": {
          flexDirection: "column-reverse",
        },
        "& p": {
          color: "#000B60",
        },
      },
      tokenPrice: {},
      title3: {
        marginTop: 12,
      },
      btnGroup: {
        padding: 0,
        gridTemplateColumns: "1fr 1fr",
      },
    },
  };
});

export default useStyles;
