// @ts-nocheck
import {
  Hidden,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withWidth,
} from "@material-ui/core";
import { numberWithCommas } from "../../../../utils/formatNumber";
import useStyles from "./style";

function createData(
  name: string,
  bronze: number,
  silver: number,
  gold: number,
  diamond: number
) {
  return { name, bronze, silver, gold, diamond };
}

const rows = [
  createData("Refund Eligability", 1, 1, 1, 1),
  createData("Guaranteed Round", 0, 1, 1, 1),
  createData("FCFS Round", 1, 1, 1, 1),
  createData("Social interaction required", 1, 0, 0, 0),
  createData("CrowdFunding & Private Pools", 0, 1, 1, 1),
  createData("Incubations Airdrops", 0, 0, 0, 1),
  createData("Freemium Access to RWA Inc. marketplace", 0, 1, 1, 1),
];

const rowsMobile = [
  {
    name: "Bronze",
    values: [
      "Minimum amount of point required : <span>2000</span>",
      "Social interaction requirements",
    ],
  },
  {
    name: "Silver",
    values: [
      "Minimum amount of point required : <span>20,000</span>",
      "Social interaction requirements",
    ],
  },
  {
    name: "Gold",
    values: [
      "Minimum amount of point required : <span>50,000</span>",
      "Guaranteed allocation",
      "Exclusive pools",
    ],
  },
  {
    name: "Diamond",
    values: [
      "Minimum amount of RWA point required : <span>200,000</span>",
      "Guaranteed allocation",
      "Exclusive pools",
      "Occasional airdrop of NFT and tokens",
      "Private prosperity group",
    ],
  },
];

const TierBenefits = (props: any) => {
  const styles = useStyles();
  return (
    <div className={styles.tabTierBenefits}>
      <Hidden smDown>
        <TableContainer component={Paper} className={styles.tableContainer}>
          <Table className={styles.table} aria-label="simple table">
            <TableHead className={styles.tableHeaderWrapper}>
              <TableRow>
                <TableCell>Tier Benefits</TableCell>
                <TableCell className={styles.tierHeader} align="right">
                  <p>Bronze</p>
                </TableCell>
                <TableCell className={styles.tierHeader} align="right">
                  <p>Silver</p>
                </TableCell>
                <TableCell className={styles.tierHeader} align="right">
                  <p>Gold</p>
                </TableCell>
                <TableCell className={styles.tierHeader} align="right">
                  <p>Diamond</p>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  Minimum Staking Points
                </TableCell>
                <TableCell component="th" scope="row" align="right">
                  {numberWithCommas("2000")}
                </TableCell>
                <TableCell component="th" scope="row" align="right">
                  {numberWithCommas("20000")}
                </TableCell>
                <TableCell component="th" scope="row" align="right">
                  {numberWithCommas("50000")}
                </TableCell>
                <TableCell component="th" scope="row" align="right">
                  {numberWithCommas("200000")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Pool Weight / Allocation Multiplier
                </TableCell>
                <TableCell component="th" scope="row" align="right">
                  1x
                </TableCell>
                <TableCell component="th" scope="row" align="right">
                  4x
                </TableCell>
                <TableCell component="th" scope="row" align="right">
                  10x
                </TableCell>
                <TableCell component="th" scope="row" align="right">
                  40x
                </TableCell>
              </TableRow>
              {rows.map((row) => (
                <TableRow key={row.name}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell component="th" scope="row" align="right">
                    <img
                      src={`/images/account_v3/icons/icon_table_${
                        row.bronze === 0 ? "false" : "true"
                      }.svg`}
                      alt=""
                    />
                  </TableCell>
                  <TableCell component="th" scope="row" align="right">
                    <img
                      src={`/images/account_v3/icons/icon_table_${
                        row.silver === 0 ? "false" : "true"
                      }.svg`}
                      alt=""
                    />
                  </TableCell>
                  <TableCell component="th" scope="row" align="right">
                    <img
                      src={`/images/account_v3/icons/icon_table_${
                        row.gold === 0 ? "false" : "true"
                      }.svg`}
                      alt=""
                    />
                  </TableCell>
                  <TableCell component="th" scope="row" align="right">
                    <img
                      src={`/images/account_v3/icons/icon_table_${
                        row.diamond === 0 ? "false" : "true"
                      }.svg`}
                      alt=""
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Hidden>

      <Hidden mdUp>
        <div className={styles.tierBenefitsMobile}>
          <TableContainer component={Paper} className={styles.tableContainer}>
            <h2>Minimum Staking Points</h2>
            <Table className={styles.table} aria-label="simple table">
              <TableHead className={styles.tableHeaderWrapper}>
                <TableRow>
                  <TableCell className={styles.tierHeader} align="right">
                    Bronze
                  </TableCell>
                  <TableCell className={styles.tierHeader} align="right">
                    Silver
                  </TableCell>
                  <TableCell className={styles.tierHeader} align="right">
                    Gold
                  </TableCell>
                  <TableCell className={styles.tierHeader} align="right">
                    Diamond
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row" align="right">
                    {numberWithCommas("2000")}
                  </TableCell>
                  <TableCell component="th" scope="row" align="right">
                    {numberWithCommas("20000")}
                  </TableCell>
                  <TableCell component="th" scope="row" align="right">
                    {numberWithCommas("50000")}
                  </TableCell>
                  <TableCell component="th" scope="row" align="right">
                    {numberWithCommas("200000")}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer component={Paper} className={styles.tableContainer}>
            <h2>Pool Weight / Allocation Multiplier</h2>
            <Table className={styles.table} aria-label="simple table">
              <TableHead className={styles.tableHeaderWrapper}>
                <TableRow>
                  <TableCell className={styles.tierHeader} align="right">
                    Bronze
                  </TableCell>
                  <TableCell className={styles.tierHeader} align="right">
                    Silver
                  </TableCell>
                  <TableCell className={styles.tierHeader} align="right">
                    Gold
                  </TableCell>
                  <TableCell className={styles.tierHeader} align="right">
                    Diamond
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row" align="right">
                    1x
                  </TableCell>
                  <TableCell component="th" scope="row" align="right">
                    4x
                  </TableCell>
                  <TableCell component="th" scope="row" align="right">
                    10x
                  </TableCell>
                  <TableCell component="th" scope="row" align="right">
                    40x
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          {rows.map((row) => (
            <TableContainer component={Paper} className={styles.tableContainer}>
              <h2>{row.name}</h2>
              <Table className={styles.table} aria-label="simple table">
                <TableHead className={styles.tableHeaderWrapper}>
                  <TableRow>
                    <TableCell className={styles.tierHeader} align="right">
                      Bronze
                    </TableCell>
                    <TableCell className={styles.tierHeader} align="right">
                      Silver
                    </TableCell>
                    <TableCell className={styles.tierHeader} align="right">
                      Gold
                    </TableCell>
                    <TableCell className={styles.tierHeader} align="right">
                      Diamond
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row" align="right">
                      <img
                        src={`/images/account_v3/icons/icon_table_${
                          row.bronze === 0 ? "false" : "true"
                        }.svg`}
                        alt=""
                      />
                    </TableCell>
                    <TableCell component="th" scope="row" align="right">
                      <img
                        src={`/images/account_v3/icons/icon_table_${
                          row.silver === 0 ? "false" : "true"
                        }.svg`}
                        alt=""
                      />
                    </TableCell>
                    <TableCell component="th" scope="row" align="right">
                      <img
                        src={`/images/account_v3/icons/icon_table_${
                          row.gold === 0 ? "false" : "true"
                        }.svg`}
                        alt=""
                      />
                    </TableCell>
                    <TableCell component="th" scope="row" align="right">
                      <img
                        src={`/images/account_v3/icons/icon_table_${
                          row.diamond === 0 ? "false" : "true"
                        }.svg`}
                        alt=""
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          ))}
        </div>
      </Hidden>
    </div>
  );
};

export default withWidth()(TierBenefits);
