import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useWeb3React } from '@web3-react/core';

import useUserClaimRefundSignature from './useUserClaimRefundSignature';
import useWalletSignature from '../../../hooks/useWalletSignature';
import PreSale_ABI from '../../../abi/PreSalePool.json';
import { getContract } from '../../../utils/contract';
import { alertSuccess, alertFailure } from '../../../store/actions/alert';
import { REFUND_TOKEN_TYPE } from '../../../constants'
import {Web3Provider} from "@ethersproject/providers";

const useClaimRefundToken = (poolAddress: string | undefined, poolId: number | undefined) => {
    const { provider: library, account } = useWeb3React();
    const dispatch = useDispatch();

    const [claimRefundTokenSuccess, setClaimRefundTokenSuccess] = useState<boolean>(false);
    const [claimRefundTransactionHash, setClaimRefundTransactionHash] = useState("");
    const [loadingClaimRefundToken, setLoadingClaimRefundToken] = useState<boolean>(false);
    const [refundError, setRefundError] = useState<string>("");

    const { error, signMessage, signature: authSignature, setSignature } = useWalletSignature();
    const { signature, currency, error: refundSignError, setSignature: setUserRefundSignature, loadingRefund } = useUserClaimRefundSignature(account, poolId, authSignature, REFUND_TOKEN_TYPE.CLAIM);

    useEffect(() => {
        poolAddress &&
            signature &&
            currency &&
            !refundError &&
            !loadingRefund &&
            claimRefundTokenWithSignature(signature, currency);
    }, [signature, poolAddress, refundError, loadingRefund]);

    useEffect(() => {
        if (error || refundSignError) {
            const errorMessage = error || refundSignError;
            setRefundError(errorMessage as string);
            setLoadingClaimRefundToken(false);
            setSignature("");
            setUserRefundSignature("");
        }
    }, [error, refundSignError]);

    const claimRefundTokenWithSignature = useCallback(
        async (signature: string, currency: string) => {

            console.log('poolAddress, signature, account:', poolAddress, signature, account);
            if (poolAddress && signature && account) {
                try {
                    const contract = getContract(poolAddress, PreSale_ABI, library as Web3Provider, account as string);
                    if (contract) {
                        const transaction = await contract.claimRefundTokens(account, currency, signature);

                        setSignature("");
                        setUserRefundSignature("");
                        setClaimRefundTransactionHash(transaction.hash);

                        await transaction.wait(1);
                        console.log({ claimRefundTokenSuccess })
                        setClaimRefundTokenSuccess(true);
                        console.log({ claimRefundTokenSuccess })
                        setLoadingClaimRefundToken(false);
                        dispatch(alertSuccess("Token Refund Successful"));
                    }
                } catch (err: any) {
                    dispatch(alertFailure(err.message));
                    console.log({ claimRefundTokenSuccess })
                    setLoadingClaimRefundToken(false);
                    setRefundError(err.message);
                    setSignature("");
                    setUserRefundSignature("");
                }
            }
        }, [poolAddress, library, account, signature]);

    const claimRefundToken = useCallback(async () => {
        if (poolAddress) {
            try {
                setClaimRefundTransactionHash("");
                setRefundError("");
                setLoadingClaimRefundToken(true);
                console.log({ claimRefundTokenSuccess })
                setClaimRefundTokenSuccess(false);

                await signMessage();
            } catch (err: any) {
                dispatch(alertFailure(err.message));
                console.log({ claimRefundTokenSuccess })
                setLoadingClaimRefundToken(false);
                setRefundError(err.message);
                setSignature("");
            }
        }
    }, [poolAddress, library, account]);

    return {
        claimRefundToken,
        transactionHashClaimRefundToken: claimRefundTransactionHash,
        loadingClaimRefund: loadingClaimRefundToken,
        setLoadingClaimRefundToken,
        setClaimRefundTransactionHash,
        claimRefundTokenSuccess,
        claimRefundError: refundError
    }
}

export default useClaimRefundToken
