import useStyles from "./style";

const Partners = () => {
  const styles = useStyles();
  const slides = [
    "/images/landing/rootstock.svg",
    "/images/landing/celestia.svg",
    "/images/landing/rootstock.svg",
    "/images/landing/celestia.svg",
    "/images/landing/rootstock.svg",
    "/images/landing/celestia.svg",
    "/images/landing/rootstock.svg",
    "/images/landing/celestia.svg",
    "/images/landing/rootstock.svg",
    "/images/landing/celestia.svg",
    "/images/landing/rootstock.svg",
    "/images/landing/celestia.svg",
    "/images/landing/rootstock.svg",
    "/images/landing/celestia.svg",
    "/images/landing/rootstock.svg",
    "/images/landing/celestia.svg",
  ];

  return (
    <div className={styles.main}>
        <div className={styles.slide}>
          <div className="home-slide">
            {[...slides, ...slides]?.map((slide, index) => {
              return <img key={index} src={slide} alt="slide" />;
            })}
          </div>
        </div>
    </div>
  );
};

export default Partners;
