import { useContext } from "react";
import useStyles from "./style";
import { AppContext } from "../../../AppContext";

const UserProcess = () => {
  const styles = useStyles();
  const { currentConnectedWallet, setOpenConnectWallet } =
    useContext(AppContext);

  const currentAccount =
    currentConnectedWallet && currentConnectedWallet.addresses[0];

  const handleConnectWalletOpen = () => {
    setOpenConnectWallet && setOpenConnectWallet(true);
  };

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.borderGradient}>
          <div className={styles.title}>
            <h6>User Process</h6>
            <p>Participate in your first sale in just 4 steps.</p>
          </div>
          <div className={styles.step}>
            <div className={styles.stepItem}>
              <div>
                <img
                  src="/images/landing/user_process.svg"
                  width={32}
                  height={32}
                  alt="process"
                />
                <h6>Connect Wallet</h6>
              </div>
              <p>Connect your favorite crypto wallet.</p>
              {!currentAccount && (
                <button onClick={() => handleConnectWalletOpen()}>
                  <div>Connect</div>
                </button>
              )}
            </div>
            <div className={styles.stepItem}>
              <div>
                <img
                  src="/images/landing/user_process.svg"
                  width={32}
                  height={32}
                  alt="process"
                />
                <h6>Complete KYC</h6>
              </div>
              <p>You only need to KYC when you win.</p>
            </div>
            <div className={styles.stepItem}>
              <div>
                <img
                  src="/images/landing/user_process.svg"
                  width={32}
                  height={32}
                  alt="process"
                />
                <h6>Participate in a sale</h6>
              </div>
              <p>
                Join your first sale and be eligible for a chance to win $25 in
                free IDO tokens.
              </p>
            </div>
            <div className={styles.stepItem}>
              <div>
                <img
                  src="/images/landing/user_process.svg"
                  width={32}
                  height={32}
                  alt="process"
                />
                <h6>Join IDO</h6>
              </div>
              <p>
                Pay in crypto, contribute to your winning sale, claim your
                tokens upon official listing.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProcess;
