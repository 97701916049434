import { Tooltip } from "@material-ui/core";
import commonStyle from "../../../../styles/CommonStyle";
import { ONE_DAY_IN_SECONDS } from "../../../StakingPools/Pool/LinearPool";
import useStyles from "./style";
import React from "react";
import { useHistory } from "react-router";

const nextIcon = "/images/icons/next.svg";

export const StakingCard = (props: any) => {
  const { cardInfo = {} } = props;
  const styles = useStyles();
  const common = commonStyle();
  const history = useHistory();

  const goToPoolDetail = () => {
    history.push(`/staking-pools`);
  };
  
  return (
    <div className={styles.cardContainer} onClick={goToPoolDetail}>
      <div className={`${styles.layerContainer}`}>
        <div className={styles.layerTitle}>
          <div className={styles.numberDay}>
            {cardInfo.title?.replace("days", "")?.trim()}
          </div>
          <div>
            <p className={styles.cardTitle}>{cardInfo.title} Staking Pool</p>
            {cardInfo?.point_rate > 0 ? (
              <span className={styles.cardSubTitle}>
                {cardInfo?.point_rate}x Multiplier
              </span>
            ) : (
              <span className={styles.cardSubTitle}>Without IDO</span>
            )}
          </div>
        </div>
        <div className={styles.subInfoContent}>
          <div className={styles.subInfo}>
            <div>APR</div>
            <div>{cardInfo?.APR}%</div>
          </div>
          <div className={styles.subInfo}>
            <div>Lock-up term</div>
            <div>
              {Number(cardInfo?.lockDuration) > 0
                ? `${(
                    Number(cardInfo?.lockDuration) / ONE_DAY_IN_SECONDS
                  ).toFixed(0)} days`
                : "None"}
            </div>
          </div>
        </div>
        <div className={styles.subInfo}>
          <div>Withdrawal delay time</div>
          <div>
            {Number(cardInfo?.delayDuration) > 0
              ? `${(
                  Number(cardInfo?.delayDuration) / ONE_DAY_IN_SECONDS
                ).toFixed(0)} days`
              : "None"}
          </div>
        </div>
        <a href="/staking-pools" className={styles.btn}>
          <button>Discover</button>
        </a>
      </div>
    </div>
  );
};
