const list = [
  {
    id: 1,
    title: "How to stake?",
    url: "https://www.rwa.inc/staking",
  },
  {
    id: 2,
    title: "My Tier FAQs",
    url: "https://www.rwa.inc/FAQ",
  },
  {
    id: 3,
    title: "What is the RWA tiers?",
    url: "https://www.rwa.inc/tiers",
  },
  {
    id: 4,
    title: "What are RWAs (RWA Staking Points?",
    url: "https://www.rwa.inc/stakingpoints",
  },
];

export const listQA = { list: list };
