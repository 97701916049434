import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    countdownPart: {
      marginTop: 6,
      display: "inline-block",
      listStyleType: "none",
      justifyContent: "center",
      color: "white",
      font: "normal normal 500 16px/24px ",

      "& span": {
        display: "block",
        margin: "0 auto",
        textAlign: "center",
      },
      "& span:first-child": {
        width: 32,
        height: 32,
        background: "#F2F6FE",
        borderRadius: 4,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlgin: "center",
        color: "#000B60",
        fontSize: "18px",
        lineHeight: "21px",
        fontWeight: 600,
      },
    },

    listCountDown: {
      display: "flex",
      // justifyContent: "center",
    },

    colon: {
      fontSize: "10px",
      lineHeight: "12px",
      color: "#000B60",
      width: 8,
      margin: 3,
      paddingTop: 13,
      display: "flex",
      justifyContent: "center",
      fontWeight: 600,
    },

    countdownInfo: {
      fontSize: "10px",
      lineHeight: "12px",
      color: "#000B60",
      marginTop: 2,
    },
  };
});

export default useStyles;
