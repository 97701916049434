import withWidth from "@material-ui/core/withWidth";
import { isNumber } from "lodash";
import moment from "moment";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import DefaultLayout from "../../components/Layout/DefaultLayout";
import { KYC_STATUS, POOL_IS_PRIVATE, TIER_LEVELS } from "../../constants";
import {
  BSC_CHAIN_ID,
  ETH_CHAIN_ID,
  POLYGON_CHAIN_ID,
  AVALANCHE_CHAIN_ID,
  ARBITRUM_CHAIN_ID,
  BASE_CHAIN_ID,
} from "../../constants/network";
import useAuth from "../../hooks/useAuth";
import useFetch from "../../hooks/useFetch";
import usePoolDetails from "../../hooks/usePoolDetails";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { pushMessage } from "../../store/actions/message";
import { sotaTiersActions } from "../../store/constants/sota-tiers";
import useCommonStyle from "../../styles/CommonStyle";
import {
  getPoolCountDown,
  getPoolCountDownPreOrder,
} from "../../utils/getPoolCountDown";
import { PoolStatus } from "../../utils/getPoolStatus";
import { getPoolStatusByPoolDetail } from "../../utils/getPoolStatusByPoolDetail";
import {
  checkAllowUserBuyPreOrder,
  checkIsEnoughTierPreOrder,
  checkIsInPreOrderTime,
  checkIsPoolPreOrder,
} from "../../utils/preOrder";
import { getIconCurrencyUsdt } from "../../utils/usdt";
import ApplyWhitelistModal from "./ApplyWhitelistModal/ApplyWhitelistModal";
import BuyTokenForm from "./BuyTokenForm";
import BuyTokenPoolDetails from "./BuyTokenPoolDetails";
import BuyTokenPoolTimeLine, {
  getDateTimeDisplay,
} from "./BuyTokenPoolTimeLine";
// new component update ui
import ByTokenHeader from "./ByTokenHeader";
import BannerNotification from "./ByTokenHeader/BannerNotification";
import ClaimToken from "./ClaimToken";
import usePoolDetailsMapping from "./hooks/usePoolDetailsMapping";
import usePoolJoinAction from "./hooks/usePoolJoinAction";
import useTokenSoldProgress from "./hooks/useTokenSoldProgress";
import useWhitelistSubmissionDetail from "./hooks/useWhitelistSubmissionDetail";
import LotteryWinners from "./LotteryWinners";
import useStyles from "./style";
import WhitelistNotificationModal from "./WhitelistNotificationModal/WhitelistNotificationModal";
import { getConfigHeader } from "../../utils/configHeader";
import { BaseRequest } from "../../request/Request";
import { apiRoute } from "../../utils";
import { alertFailure } from "../../store/actions/alert";
import BannerNotificationDisconnected from "./ByTokenHeader/BannerNotificationDisconnected";
import { AppContext } from "../../AppContext";

enum TabDetais {
  Info = "Project Info",
  Swap = "Swap & Claim",
  Winner = "Winner",
}

const BuyToken: React.FC<any> = (props: any) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const commonStyle = useCommonStyle();

  const [buyTokenSuccess, setBuyTokenSuccess] = useState<boolean>(false);
  const [isRefunded, setIsRefunded] = useState<boolean>(false);
  const [wrongBuyChain, setWrongBuyChain] = useState<boolean>(false);
  const [wrongClaimChain, setWrongClaimChain] = useState<boolean>(false);

  const [applyLoading, setApplyLoading] = useState<boolean>(false);
  const [showWhitelistNotificationModal, setShowWhitelistNotificationModal] =
    useState<boolean>(false);

  const [refetch, setRefetch] = useState<boolean>(false);

  const { pathname } = useLocation();
  const { id } = useParams() as any;
  /* const userTier = useTypedSelector(state => state.userTier).data; */
  const { appChainID } = useTypedSelector((state) => state.appNetwork).data;
  const { poolDetails } = usePoolDetails(id);
  const { connectedAccount, wrongChain } = useAuth();
  // Fetch token sold, total tokens sold
  const { tokenSold, soldProgress } = useTokenSoldProgress(
    poolDetails?.poolAddress,
    poolDetails?.amount,
    poolDetails?.networkAvailable,
    poolDetails
  );
  const { joinPool, poolJoinLoading, joinPoolSuccess } = usePoolJoinAction({
    poolId: poolDetails?.id,
    poolDetails,
  });
  const { currentConnectedWallet } = useContext(AppContext);

  const configTokenHeader = useMemo(() => {
    return getConfigHeader(connectedAccount);
  }, [connectedAccount]);

  const onApplyWhitelist = async () => {
    if (
      !(
        dataUser?.user?.is_kyc === KYC_STATUS.APPROVED || poolDetails?.kycBypass
      )
    ) {
      return;
    }

    await joinPool("");
    if (currentUserTier?.level <= 1) {
      setShowWhitelistNotificationModal(true);
    }
  };

  const { data: existedWinner, loading: existedWinnerLoading } = useFetch<
    Array<any>
  >(
    poolDetails && connectedAccount
      ? `/pool/${poolDetails?.id}/check-exist-winner?wallet_address=${connectedAccount}`
      : undefined,
    poolDetails?.method !== "whitelist",
    configTokenHeader,
    false
  );

  const { data: dataUser, loading: dataUserLoading } = useFetch<any>(
    connectedAccount
      ? `/user/profile?wallet_address=${connectedAccount}`
      : undefined,
    false,
    configTokenHeader,
    false
  );
  const { data: pickedWinner } = useFetch<Array<any>>(
    poolDetails ? `/pool/${poolDetails?.id}/check-picked-winner` : undefined,
    poolDetails?.method !== "whitelist"
  );

  const { data: alreadyJoinPool, loading: joinPoolLoading } = useFetch<boolean>(
    poolDetails && connectedAccount
      ? `/user/check-join-campaign/${poolDetails?.id}?wallet_address=${connectedAccount}`
      : undefined,
    false,
    configTokenHeader,
    false
  );

  const verifiedEmail = true;
  const {
    data: currentUserTier,
    loading: tierLoading,
    refetchData: refetchCurrientTier,
  } = useFetch<any>(
    id && connectedAccount
      ? `pool/${id}/user/current-tier?wallet_address=${connectedAccount}`
      : undefined,
    false,
    configTokenHeader,
    false
  );

  const { data: winnersList, loading: winnersListLoading } = useFetch<any>(
    id ? `/user/winner-list/${id}?page=1&limit=10&` : undefined
  );

  const refetchTier = () => {
    let newUri =
      id && connectedAccount ? `pool/${id}/user/current-tier` : undefined;
    refetchCurrientTier(newUri);
  };

  // const firstLoading = !(winnersList && connectedAccount);
  const firstLoading = !(!winnersListLoading && connectedAccount);
  const totalLoading =
    existedWinnerLoading ||
    dataUserLoading ||
    joinPoolLoading ||
    tierLoading ||
    poolJoinLoading ||
    firstLoading;

  const poolDetailsMapping = usePoolDetailsMapping(poolDetails);

  // const countDown = useCountDownFreeBuyTime(poolDetails);

  // Use for check whether pool exist in selected network or not
  const appNetwork = useMemo(() => {
    switch (appChainID) {
      case BSC_CHAIN_ID:
        return "bsc";

      case BASE_CHAIN_ID:
        return "base";

      case POLYGON_CHAIN_ID:
        return "polygon";

      case ETH_CHAIN_ID:
        return "eth";

      case AVALANCHE_CHAIN_ID:
        return "avalanche";

      case ARBITRUM_CHAIN_ID:
        return "arbitrum";
    }
  }, [appChainID]);
  const ableToFetchFromBlockchain =
    appNetwork === poolDetails?.networkAvailable && !wrongChain;
  const userBuyLimit = currentUserTier?.max_buy || 0;
  const userBuyMinimum = currentUserTier?.min_buy || 0;
  const currentUserTierLevel = currentUserTier?.level || 0;
  const userReferralAllocation = currentUserTier?.referral_alloc || 0;
  const userGuaranteeAllocation = currentUserTier?.guarantee_alloc || 0;
  const userFcfsAllocation = currentUserTier?.fcfs_alloc || 0;

  const [isPreOrderPool, setIsPreOrderPool] = useState<boolean>(false);
  const [isEnoughTierPreOrder, setIsEnoughTierPreOrder] =
    useState<boolean>(false);
  const [isInPreOrderTime, setIsInPreOrderTime] = useState<boolean>(false);
  const [allowUserBuyPreOrder, setAllowUserBuyPreOrder] =
    useState<boolean>(false);

  const getIsPreOrderPool = useCallback(() => {
    return checkIsPoolPreOrder({ poolDetails, currentUserTierLevel });
  }, [poolDetails, currentUserTierLevel]);
  const getIsEnoughTierPreOrder = useCallback(() => {
    return checkIsEnoughTierPreOrder({ poolDetails, currentUserTierLevel });
  }, [poolDetails, currentUserTierLevel]);
  const getIsInPreOrderTime = useCallback(() => {
    return (
      isPreOrderPool &&
      checkIsInPreOrderTime({ poolDetails, currentUserTierLevel })
    );
  }, [poolDetails, refetch, isPreOrderPool, currentUserTierLevel]);
  const getAllowUserBuyPreOrder = useCallback(() => {
    return (
      isPreOrderPool &&
      checkAllowUserBuyPreOrder({
        poolDetails,
        currentUserTierLevel,
        userJoined: alreadyJoinPool || joinPoolSuccess,
        userIsWinner: existedWinner,
      })
    );
  }, [
    poolDetails,
    refetch,
    isPreOrderPool,
    currentUserTierLevel,
    existedWinner,
    alreadyJoinPool,
    joinPoolSuccess,
    connectedAccount,
  ]);

  const [poolTimeline, setPoolTimeline] = useState<any>({});

  useEffect(() => {
    if (!poolDetails) return;

    // With Whitelist situation, Enable when join time < current < end join time
    // With FCFS, always disable join button
    const joinTimeInDate = poolDetails?.joinTime
      ? new Date(Number(poolDetails?.joinTime) * 1000)
      : undefined;
    const endJoinTimeInDate = poolDetails?.endJoinTime
      ? new Date(Number(poolDetails?.endJoinTime) * 1000)
      : undefined;

    const startPreOrderTime = poolDetails?.startPreOrderTime
      ? new Date(Number(poolDetails?.startPreOrderTime) * 1000)
      : undefined;
    const startBuyTimeNormal = poolDetails?.startBuyTime
      ? new Date(Number(poolDetails?.startBuyTime) * 1000)
      : undefined;
    const startBuyTimeInDate = isInPreOrderTime
      ? startPreOrderTime
      : startBuyTimeNormal;

    const endPreOrderTime = startBuyTimeNormal;

    const endBuyTimeNormal = poolDetails?.endBuyTime
      ? new Date(Number(poolDetails?.endBuyTime) * 1000)
      : undefined;
    const endBuyTimeInDate = isInPreOrderTime
      ? endPreOrderTime
      : endBuyTimeNormal;

    const announcementTime = poolDetails?.announcement_time
      ? new Date(Number(poolDetails?.announcement_time) * 1000)
      : undefined;
    const releaseTimeInDate = poolDetails?.releaseTime
      ? new Date(Number(poolDetails?.releaseTime) * 1000)
      : undefined;

    setPoolTimeline({
      joinTimeInDate,
      endJoinTimeInDate,
      startBuyTimeNormal,
      endBuyTimeNormal,
      startBuyTimeInDate,
      endBuyTimeInDate,
      announcementTime,
      releaseTimeInDate,
    });
  }, [poolDetails, currentUserTierLevel, isInPreOrderTime]);
  const {
    joinTimeInDate,
    endJoinTimeInDate,
    startBuyTimeNormal,
    endBuyTimeNormal,
    startBuyTimeInDate,
    endBuyTimeInDate,
    releaseTimeInDate,
  } = poolTimeline;

  const [activeTabBottom, setActiveTabBottom] = useState<TabDetais>(
    TabDetais.Info
  );
  const [textSwapTab, setTextSwapTab] = useState<string>("Swap & Claim");
  const [numberWiner, setNumberWiner] = useState(0);

  // Get Currency Info
  const { currencyName } = getIconCurrencyUsdt({
    purchasableCurrency: poolDetails?.purchasableCurrency,
    networkAvailable: poolDetails?.networkAvailable,
  });

  const today = new Date();

  const availablePurchase =
    startBuyTimeInDate &&
    endBuyTimeInDate &&
    today >= startBuyTimeInDate &&
    today <= endBuyTimeInDate &&
    /* today >= tierStartBuyInDate && */
    /* today <= tierEndBuyInDate && */
    poolDetails?.isDeployed &&
    verifiedEmail;

  // const poolStatus = getPoolStatusByPoolDetail(
  //   poolDetails,
  //   tokenSold
  // );
  const getPoolStatus = useCallback(() => {
    return getPoolStatusByPoolDetail(poolDetails, tokenSold);
  }, [poolDetails, refetch, tokenSold]);
  const [poolStatus, setPoolStatus] = useState<PoolStatus | undefined>(
    undefined
  );

  const displayCountDownTime = useCallback(
    (
      method: string | undefined,
      startJoinTime: Date | undefined,
      endJoinTime: Date | undefined,
      startBuyTime: Date | undefined,
      endBuyTime: Date | undefined
    ) => {
      if (isEnoughTierPreOrder && isInPreOrderTime) {
        // Pool is PreOrder Pool and Pool in PreOrder Time Actived
        return getPoolCountDownPreOrder({ endBuyTime });
      }
      return getPoolCountDown(
        startJoinTime,
        endJoinTime,
        startBuyTime,
        endBuyTime,
        releaseTimeInDate,
        method,
        // poolDetails?.campaignStatus,
        poolStatus,
        poolDetails,
        soldProgress,
        poolDetails?.isPrivate
      );
    },
    [
      poolStatus,
      poolDetails?.method,
      poolDetails?.isPrivate,
      joinTimeInDate,
      endJoinTimeInDate,
      startBuyTimeInDate,
      endBuyTimeInDate,
    ]
  );

  // const { date: countDownDate, display } = (isEnoughTierPreOrder && isInPreOrderTime)
  //     ? displayCountDownTime(poolDetails?.method, joinTimeInDate, endJoinTimeInDate, startBuyTimeInDate, endBuyTimeInDate)
  //     : displayCountDownTime(poolDetails?.method, joinTimeInDate, endJoinTimeInDate, startBuyTimeNormal, endBuyTimeNormal);

  const getTimeline = useCallback(() => {
    let enoughInPreOrder = isEnoughTierPreOrder && isInPreOrderTime;
    let startTime = enoughInPreOrder ? startBuyTimeInDate : startBuyTimeNormal;
    let endTime = enoughInPreOrder ? endBuyTimeInDate : endBuyTimeNormal;
    let { date: countDownDate, display } = displayCountDownTime(
      poolDetails?.method,
      joinTimeInDate,
      endJoinTimeInDate,
      startTime,
      endTime
    );
    return { countDownDate, display };
  }, [poolDetails, poolStatus]);

  const [timeline, setTimeline] = useState({
    countDownDate: undefined,
    display: "",
  });

  useEffect(() => {
    if (!poolDetails) return;

    setIsPreOrderPool(getIsPreOrderPool());
    setIsEnoughTierPreOrder(getIsEnoughTierPreOrder());
    setIsInPreOrderTime(getIsInPreOrderTime());
    setAllowUserBuyPreOrder(getAllowUserBuyPreOrder());

    setPoolStatus(getPoolStatus());

    setTimeline(getTimeline());
  }, [poolDetails, refetch, firstLoading, totalLoading, soldProgress]);

  useEffect(() => {
    if (!poolStatus) return;
    let activeTab = TabDetais.Info,
      newTextSwapTab = "Swap & Claim";

    switch (poolStatus) {
      case PoolStatus.Upcoming:
        if (existedWinner) {
          activeTab = TabDetais.Winner;
        }
        break;
      case PoolStatus.Progress:
        activeTab = TabDetais.Swap;
        newTextSwapTab = "Swap & Claim";
        break;
      case PoolStatus.Claimable:
      case PoolStatus.Closed:
      case PoolStatus.Filled:
        activeTab = TabDetais.Swap;
        newTextSwapTab = "Claim";
        break;

      default:
        activeTab = TabDetais.Info;
        newTextSwapTab = "Swap & Claim";
        break;
    }

    setActiveTabBottom(activeTab);
    setTextSwapTab(newTextSwapTab);
    setTimeline(getTimeline());
  }, [poolStatus]);

  function refetchPool() {
    setRefetch((prev) => !prev);
  }

  // Auto Scroll To Top When redirect from other pages
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    currentUserTier &&
      dispatch({
        type: sotaTiersActions.USER_TIER_SUCCESS,
        payload: currentUserTier.level,
      });
  }, [currentUserTier]);

  useEffect(() => {
    if (!currentConnectedWallet) {
      dispatch(pushMessage(`Please connect wallet.`));
      return;
    }
    if (!poolDetails) return;
    const appNetwork = (() => {
      switch (appChainID) {
        case BSC_CHAIN_ID:
          return "bsc";
        case BASE_CHAIN_ID:
          return "base";
        case POLYGON_CHAIN_ID:
          return "polygon";

        case ETH_CHAIN_ID:
          return "eth";
        case AVALANCHE_CHAIN_ID:
          return "avalanche";
        case ARBITRUM_CHAIN_ID:
          return "arbitrum";
      }
    })();
    const { networkAvailable, networkClaim, campaignStatus } = poolDetails;
    setWrongBuyChain(appNetwork !== networkAvailable);
    setWrongClaimChain(
      networkClaim && networkClaim !== networkAvailable
        ? appNetwork !== networkClaim
        : appNetwork !== networkAvailable
    );

    if (poolDetails && networkClaim && networkClaim !== networkAvailable) {
      if (
        campaignStatus &&
        [
          PoolStatus.Filled + "",
          PoolStatus.Claimable + "",
          PoolStatus.Closed + "",
        ].includes(campaignStatus)
      ) {
        if (appNetwork !== networkClaim) {
          dispatch(
            pushMessage(
              `Please switch to ${networkClaim.toLocaleUpperCase()} network to claim tokens.`
            )
          );
        } else {
          dispatch(pushMessage(""));
        }
      } else if (appNetwork !== networkAvailable) {
        dispatch(
          pushMessage(
            `Please switch to ${networkAvailable.toLocaleUpperCase()} network to do Apply Whitelist, Approve/Buy tokens.`
          )
        );
      } else {
        dispatch(pushMessage(""));
      }
    } else if (poolDetails && appNetwork !== networkAvailable) {
      dispatch(
        pushMessage(
          `Please switch to ${networkAvailable.toLocaleUpperCase()} network to do Apply Whitelist, Approve/Buy tokens.`
        )
      );
    } else {
      dispatch(pushMessage(""));
    }
  }, [appChainID, poolDetails, dispatch, appNetwork, currentConnectedWallet]);

  // const [showWhitelistCountryModal, setShowWhitelistCountryModal] = useState(false);

  const isOverTimeApplyWhiteList =
    endJoinTimeInDate && endJoinTimeInDate < today;
  // const isCanceledWhitelist = (userCanceledWhiteList && userCanceledWhiteList.id);
  const isKYC = !!(
    dataUser?.user?.is_kyc === KYC_STATUS.APPROVED || poolDetails?.kycBypass
  );
  const showKyc = poolDetails && !isKYC && connectedAccount;
  const showMinTier =
    poolDetails &&
    currentUserTier?.level < poolDetails.minTier &&
    !isOverTimeApplyWhiteList;
  const showSocialTask = poolDetails && currentUserTier?.level <= 1;

  const isCommunityPool =
    Number(poolDetails?.isPrivate || "0") === POOL_IS_PRIVATE.COMMUNITY;
  const region = moment.tz.guess();
  const timezone = moment.tz(region).format("Z");

  const openWhitelistNotificationModal = () => {
    setShowWhitelistNotificationModal(true);
  };

  const renderPoolDetailLeft = () => {
    return (
      <div className={styles.borderPoolDetailLeft}>
        <div className={styles.poolDetailLeft}>
          {connectedAccount ? (
            <BannerNotification
              poolDetails={poolDetails}
              ableToFetchFromBlockchain={ableToFetchFromBlockchain}
              winnersList={winnersList}
              verifiedEmail={verifiedEmail}
              currentUserTier={currentUserTier}
              existedWinner={existedWinner}
              currencyName={currencyName}
              userBuyLimit={userBuyLimit}
              alreadyJoinPool={alreadyJoinPool}
              joinPoolSuccess={joinPoolSuccess}
              connectedAccount={connectedAccount}
              wrongChain={wrongChain}
              // whitelistCompleted={whitelistCompleted}
              // whitelistLoading={whitelistLoading}
              // whitelistSubmission={whitelistSubmission}
              isOverTimeApplyWhiteList={isOverTimeApplyWhiteList}
              // isInPreOrderTime={isInPreOrderTime}
              poolStatus={poolStatus}
              refetch={refetch}
              totalLoading={totalLoading}
              tokenSold={tokenSold}
              soldProgress={soldProgress}
              // Button
              poolTimeline={poolTimeline}
              poolJoinLoading={poolJoinLoading}
              onApplyWhitelist={onApplyWhitelist}
              openWhitelistNotificationModal={openWhitelistNotificationModal}
              showKyc={showKyc}
              showMinTier={showMinTier}
              isRefunded={isRefunded}
            />
          ) : (
            <BannerNotificationDisconnected
              poolDetails={poolDetails}
              ableToFetchFromBlockchain={ableToFetchFromBlockchain}
              winnersList={winnersList}
              isOverTimeApplyWhiteList={isOverTimeApplyWhiteList}
              poolStatus={poolStatus}
              refetch={refetch}
              totalLoading={!poolDetails}
              tokenSold={tokenSold}
              soldProgress={soldProgress}
              // Button
              poolTimeline={poolTimeline}
              poolJoinLoading={poolJoinLoading}
              onApplyWhitelist={onApplyWhitelist}
              openWhitelistNotificationModal={openWhitelistNotificationModal}
              isRefunded={isRefunded}
            />
          )}
          <BuyTokenPoolTimeLine
            timezone={timezone}
            currentStatus={poolStatus}
            display={timeline.display}
            poolDetails={poolDetails}
            countDownDate={timeline.countDownDate}
            refetchPoolDetails={refetchPool}
            refetchCurrentTier={refetchTier}
            isEnoughTierPreOrder={isEnoughTierPreOrder}
          />
        </div>
      </div>
    );
  };

  const renderPoolDetailRight = () => {
    const renderTextNotJoin = () => {
      let text = "All tokens are sold out. Thank you for your participation.";
      switch (poolStatus) {
        case PoolStatus.TBA:
          text = "TBA. Stay tuned for further updates!";
          break;
        case PoolStatus.Upcoming:
          text = "";
          break;
        case PoolStatus.Progress:
          text = "";
          break;
        default:
          break;
      }
      return <span className={commonStyle.nnn1424h}>{text}</span>;
    };

    const renderNavbar = () => {
      return (
        <ul className={styles.navBottom}>
          <li
            onClick={() => setActiveTabBottom(TabDetais.Info)}
            className={activeTabBottom === TabDetais.Info ? "active" : ""}
          >
            Project Info
          </li>
          <li
            onClick={() => setActiveTabBottom(TabDetais.Swap)}
            className={activeTabBottom === TabDetais.Swap ? "active" : ""}
          >
            {textSwapTab}
          </li>
          {isNumber(numberWiner) && numberWiner > 0 && !!pickedWinner && (
            <li
              onClick={() => setActiveTabBottom(TabDetais.Winner)}
              className={activeTabBottom === TabDetais.Winner ? "active" : ""}
            >
              Winner ({`${numberWiner}`})
            </li>
          )}
        </ul>
      );
    };

    const renderTabProjectInfo = () => {
      if (activeTabBottom !== TabDetais.Info) return;
      return (
        <BuyTokenPoolDetails
          currencyName={currencyName}
          poolDetails={poolDetails}
          currentUserTierLevel={currentUserTierLevel}
        />
      );
    };

    const renderTabSwapClaim = () => {
      if (activeTabBottom !== TabDetais.Swap) return;
      return (
        <>
          {startBuyTimeNormal &&
            new Date() < startBuyTimeNormal &&
            !(isEnoughTierPreOrder && existedWinner) && (
              <span className={styles.waitBuy}>
                Please wait until IDO Date & Time at{" "}
                {getDateTimeDisplay(startBuyTimeInDate)} (GMT {`${timezone}`})
              </span>
            )}

          {/* {currentUserTierLevel === 0 && renderTextNotJoin()} */}

          {poolStatus !== PoolStatus.Filled &&
            startBuyTimeNormal &&
            endBuyTimeNormal &&
            startBuyTimeNormal < new Date() &&
            new Date() < endBuyTimeNormal && (
              <>
                {(!isPreOrderPool || // Normal Pool
                  (isPreOrderPool && !isInPreOrderTime)) && ( // If Pool PreOrder and in Swap Time
                  <BuyTokenForm
                    disableAllButton={wrongBuyChain}
                    existedWinner={existedWinner}
                    alreadyJoinPool={alreadyJoinPool}
                    joinPoolSuccess={joinPoolSuccess}
                    tokenDetails={poolDetails?.tokenDetails}
                    networkAvailable={poolDetails?.networkAvailable || ""}
                    rate={poolDetails?.ethRate}
                    poolAddress={poolDetails?.poolAddress}
                    maximumBuy={userBuyLimit}
                    minimumBuy={userBuyMinimum}
                    guaranteeAllocation={userGuaranteeAllocation}
                    fcfsAllocation={userFcfsAllocation}
                    poolAmount={poolDetails?.amount}
                    purchasableCurrency={poolDetails?.purchasableCurrency?.toUpperCase()}
                    poolId={poolDetails?.id}
                    joinTime={joinTimeInDate}
                    method={poolDetails?.method}
                    availablePurchase={availablePurchase}
                    ableToFetchFromBlockchain={ableToFetchFromBlockchain}
                    minTier={poolDetails?.minTier}
                    isDeployed={poolDetails?.isDeployed}
                    connectedAccount={connectedAccount}
                    wrongChain={wrongChain}
                    // refetchPoolDetails={refetchPool}

                    // Apply Normal Time
                    startBuyTimeInDate={startBuyTimeNormal}
                    endBuyTimeInDate={endBuyTimeNormal}
                    endJoinTimeInDate={endJoinTimeInDate}
                    tokenSold={tokenSold}
                    setBuyTokenSuccess={setBuyTokenSuccess}
                    isClaimable={poolDetails?.type === "claimable"}
                    currentUserTier={currentUserTier}
                    poolDetailsMapping={poolDetailsMapping}
                    poolDetails={poolDetails}
                    // Setting Disable PreOrder
                    isInPreOrderTime={false}
                  />
                )}
              </>
            )}

          {/* Pre-Order  */}
          {poolStatus !== PoolStatus.Filled &&
            (alreadyJoinPool || joinPoolSuccess) &&
            isPreOrderPool && // Pre Order Pool
            isEnoughTierPreOrder &&
            allowUserBuyPreOrder &&
            startBuyTimeInDate &&
            endBuyTimeInDate &&
            startBuyTimeInDate < new Date() &&
            new Date() < endBuyTimeInDate && (
              <BuyTokenForm
                disableAllButton={wrongBuyChain}
                existedWinner={existedWinner}
                alreadyJoinPool={alreadyJoinPool}
                joinPoolSuccess={joinPoolSuccess}
                tokenDetails={poolDetails?.tokenDetails}
                networkAvailable={poolDetails?.networkAvailable || ""}
                rate={poolDetails?.ethRate}
                poolAddress={poolDetails?.poolAddress}
                maximumBuy={userBuyLimit}
                minimumBuy={userBuyMinimum}
                guaranteeAllocation={userGuaranteeAllocation}
                fcfsAllocation={userFcfsAllocation}
                poolAmount={poolDetails?.amount}
                purchasableCurrency={poolDetails?.purchasableCurrency?.toUpperCase()}
                poolId={poolDetails?.id}
                joinTime={joinTimeInDate}
                method={poolDetails?.method}
                availablePurchase={availablePurchase}
                ableToFetchFromBlockchain={ableToFetchFromBlockchain}
                minTier={poolDetails?.minTier}
                isDeployed={poolDetails?.isDeployed}
                connectedAccount={connectedAccount}
                wrongChain={wrongChain}
                // refetchPoolDetails={refetchPool}

                // Apply PreOrder Time
                startBuyTimeInDate={startBuyTimeInDate}
                endBuyTimeInDate={endBuyTimeInDate}
                endJoinTimeInDate={endJoinTimeInDate}
                tokenSold={tokenSold}
                setBuyTokenSuccess={setBuyTokenSuccess}
                isClaimable={poolDetails?.type === "claimable"}
                currentUserTier={currentUserTier}
                poolDetailsMapping={poolDetailsMapping}
                poolDetails={poolDetails}
                // Setting Enable PreOrder
                isInPreOrderTime={isInPreOrderTime}
              />
            )}

          {poolDetails?.campaignStatus &&
            ([
              PoolStatus.Filled + "",
              PoolStatus.Progress + "",
              PoolStatus.Claimable + "",
              PoolStatus.Closed + "",
            ].includes(poolDetails.campaignStatus) ||
              (poolDetails.campaignStatus === PoolStatus.Upcoming &&
                isEnoughTierPreOrder)) && (
              <ClaimToken
                releaseTime={
                  poolDetails?.releaseTime ? releaseTimeInDate : undefined
                }
                ableToFetchFromBlockchain={ableToFetchFromBlockchain}
                tokenDetails={poolDetails?.tokenDetails}
                buyTokenSuccess={buyTokenSuccess}
                poolId={poolDetails?.id}
                wrongChain={wrongClaimChain}
                poolDetails={poolDetails}
                currencyName={currencyName}
                startBuyTimeInDate={startBuyTimeInDate}
                isPreOrderPool={isPreOrderPool}
                allowUserBuyPreOrder={allowUserBuyPreOrder}
                startBuyTimeNormal={startBuyTimeNormal}
                userBuyLimit={userBuyLimit}
                dataUser={dataUser}
                setIsRefunded={setIsRefunded}
                poolStatus={poolDetails.campaignStatus || ""}
              />
            )}
        </>
      );
    };

    const renderTabWinner = () => {
      return (
        <div
          className={`${activeTabBottom === TabDetais.Winner && "show"} ${
            styles.hiddenTabWinner
          }`}
        >
          <LotteryWinners
            poolId={poolDetails?.id}
            title={poolDetails?.title}
            userWinLottery={!!existedWinner}
            pickedWinner={!!pickedWinner}
            currencyName={currencyName}
            userBuyLimit={userBuyLimit}
            alreadyJoinPool={alreadyJoinPool}
            joinPoolSuccess={joinPoolSuccess}
            setNumberWiner={setNumberWiner}
            isCommunityPool={isCommunityPool}
            userReferralAllocation={userReferralAllocation}
            participantNumber={poolDetails?.participantNumber}
          />
        </div>
      );
    };

    return (
      <div className={styles.borderPoolDetailsRight}>
        <div className={styles.poolDetailRight}>
          <ByTokenHeader
            poolDetailsMapping={poolDetailsMapping}
            poolDetails={poolDetails}
          />
          <div className={styles.boxBottom}>
            {renderNavbar()}

            {renderTabProjectInfo()}

            {renderTabSwapClaim()}

            {renderTabWinner()}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={styles.poolDetailContainer}>
        {renderPoolDetailLeft()}

        {renderPoolDetailRight()}

        {showWhitelistNotificationModal && (
          <WhitelistNotificationModal
            poolDetails={poolDetails}
            connectedAccount={connectedAccount}
            showKyc={showKyc}
            showMinTier={showMinTier}
            showSocialTask={showSocialTask}
            handleClose={() => {
              setShowWhitelistNotificationModal(false);
            }}
          />
        )}
      </div>
    </>
  );
};

export default withWidth()(BuyToken);
