import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return {
    main: {
      background: "#ffffff",
    },
    slide: {
      position: "relative",
      height: "100%",
      overflow: "hidden",
      "& div": {
        display: "flex",
        width: "fit-content",
        height: "100%",
        flexWrap: "nowrap",
        alignItems: "center",
      },
    },
  };
});

export default useStyles;
