import { withWidth } from "@material-ui/core";
import { useState } from "react";
import { Link } from "react-router-dom";
import useStyles from "./style";

const guideFAQs = [
  {
    name: "Guide",
    childs: [
      {
        title: "Before Joining IDOs",
        href: "https://www.rwa.inc/idos",
      },
      {
        title: "How to claim tokens?",
        href: "https://www.rwa.inc/claimtokens",
      },
      {
        title: "How to swap tokens?",
        href: "https://www.rwa.inc/tokenswaps",
      },
    ],
  },
  {
    name: "FAQ",
    childs: [
      {
        title: "Get started with RWA",
        href: "https://www.rwa.inc/getstarted",
      },
      {
        title: "Allocation Result & Buying",
        href: "https://www.rwa.inc/allo",
      },
      {
        title: "KYC",
        href: "https://www.rwa.inc/kycaml",
      },
      {
        title: "Claim",
        href: "https://www.rwa.inc/claim",
      },
      {
        title: "Staking",
        href: "https://www.rwa.inc/staking",
      },
      {
        title: "Others",
        href: "https://www.rwa.inc/misc",
      },
      {
        title: "Whitelist",
        href: "https://www.rwa.inc/whitelist",
      },
    ],
  },
];

const ButtonMailto = (props: any) => {
  return (
    <Link
      to="#"
      onClick={(e) => {
        window.location = props.mailto;
        e.preventDefault();
      }}
    >
      {props.label}
    </Link>
  );
};

const NeedHelp = (props: any) => {
  const styles = useStyles();
  const [listQuestions] = useState(guideFAQs);

  return (
    <div className={styles.pageNeedHelp}>
      <h2 className={styles.title}>Need some help?</h2>
      <div className={styles.sectionBody}>
        <div>
          <div className={styles.subTitle}>Support Email</div>
          <div className={styles.des}>
            If you have any questions, please contact us at any moment via{" "}
            <ButtonMailto
              label="Support@rwa.inc"
              mailto="mailto:support@rwa.inc"
            />
          </div>
        </div>
      </div>
      <div className={`${styles.sectionBody} ${styles.sectionBodyQuestions}`}>
        <div style={{ width: "100%" }}>
          {/* <div className={styles.subTitle}>
            Guide & FAQs
          </div>
          <div className={styles.groupSearch}>
            <input placeholder="Ask a question..."/>
            <Button>
              <img src="/images/icons/search_white.svg" alt="" />
            </Button>
          </div> */}

          {listQuestions?.map((item, index) => {
            return (
              <div className={styles.boxQuestions} key={index}>
                <div className={styles.subTitle}>{item.name}</div>
                <ul className={styles.listQuestions}>
                  {item.childs?.map((child, i) => {
                    return (
                      <li key={i} className={styles.itemQuestions}>
                        <a href={child.href} target="_blank" rel="noreferrer">
                          <span data-role="dot">&bull;</span>
                          <span data-role="title">{child.title}</span>
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default withWidth()(NeedHelp);
