import { withWidth } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { useWeb3React } from "@web3-react/core";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AppContext } from "../../../../AppContext";
import { ConnectorNames } from "../../../../constants/connectors";
import { APP_NETWORKS_SUPPORT } from "../../../../constants/network";
import { trimMiddlePartAddress } from "../../../../utils/accountAddress";
import useStyles from "./style";
import { menuAccount } from "../../../../pages/AccountV2";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      paddingTop: 0,
      borderRadius: 50,
      display: "flex",
      justifyContent: "space-between",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: "black",
      backgroundColor: "#4B4B4B",
      padding: 4,

      "&:hover": {
        backgroundColor: "#D4D4D4",
      },
    },
    svgIcon: {
      fontSize: 5,
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
  customClass: string;
}

export interface ComponentProps {
  opened: boolean;
  handleClose: () => void;
  currentWallet: any;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, customClass, onClose, ...other } = props;

  const customStyles = {
    color: "white",
    padding: 0,
  };

  return (
    <MuiDialogTitle
      disableTypography
      className={`${classes.root} ${customClass}`}
      {...other}
      style={customStyles}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    color: "#AEAEAE",
  },
}))(MuiDialogContent);

const WalletDisconnect: React.FC<ComponentProps> = (props: any) => {
  const styles = useStyles();
  const { logout: disconnectWallet } = useContext(AppContext);
  const { appChainID } = useSelector((state: any) => state.appNetwork).data;
  const { opened, handleClose, currentWallet } = props;

  const walletName = currentWallet && currentWallet.title;
  const address = currentWallet ? currentWallet.addresses[0] : "";
  const balance = address ? currentWallet.balances[address] : 0;
  const networkInfo = APP_NETWORKS_SUPPORT[appChainID] || {};
  const walletIconPath = currentWallet
    ? `/images/${currentWallet.typeId}.svg`
    : "";

  const { connector } = useWeb3React();
  const handleAccountLogout = async () => {
    if (
      walletName === ConnectorNames.WalletConnect &&
      localStorage.getItem("walletconnect")
    ) {
      localStorage.removeItem("walletconnect");
    }

    handleClose();
    disconnectWallet && disconnectWallet();
  };

  return (
    <Dialog open={opened} onClose={handleClose} className={styles.dialog}>
      <DialogTitle
        id="customized-dialog-title"
        onClose={handleClose}
        customClass={styles.dialogTitle}
      >
        <div className={styles.disconnectTitle}>
          <div className={styles.accountTitle}>
            <div>Account&nbsp;&nbsp;</div>
            <Link to={`/account/${menuAccount?.my_profile?.key}`}>
              <img
                src="/images/icons/open_account.svg"
                alt=""
                onClick={handleClose}
              />
            </Link>
          </div>
          <div
            className={styles.disconnectButton}
            onClick={handleAccountLogout}
          >
            <img
              src="/images/icons/disconnect.svg"
              className={styles.disconnectIcon}
              alt=""
            />
            <span>Disconnect</span>
          </div>
        </div>
      </DialogTitle>
      <DialogContent className={styles.dialogContent}>
        <div className={styles.mainContent}>
          <div className={styles.mainDialog}>
            {walletIconPath && (
              <img
                src={walletIconPath}
                width={80}
                height={80}
                alt={walletName}
                className={styles.walletNameIcon}
              />
            )}
            <div className={styles.walletAddress}>
              {trimMiddlePartAddress(address, 6)}
              {/* {isWidthDown("xs", props.width) &&
              trimMiddlePartAddress(address, 10)} */}
            </div>
          </div>
          <div className={styles.accountDetails}>
            <div className={styles.accountItem}>
              <div>Balance</div>
              <div>
                {balance} {networkInfo.currency}
              </div>
            </div>
            <div className={styles.accountItem}>
              <div>Network</div>
              <div>
                <img
                  src={networkInfo.icon}
                  alt=""
                  className={styles.netWorkIcon}
                />
                <p>{networkInfo.networkName}</p>
              </div>
            </div>
            <div className={styles.accountItem}>
              <div>Wallet</div>
              <div>{walletName}</div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default withWidth()(WalletDisconnect);
