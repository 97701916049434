import { useState, useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import { useDispatch } from "react-redux";
import { ethers } from "ethers";

import { alertFailure } from "../store/actions/alert";
import { useTypedSelector } from "../hooks/useTypedSelector";
import { ConnectorNames, connectorNames } from "../constants/connectors";
import { getErrorMessage } from "../utils/getErrorMessage";

const MESSAGE_INVESTOR_SIGNATURE =
  process.env.REACT_APP_MESSAGE_INVESTOR_SIGNATURE || "";

const rawMessage = MESSAGE_INVESTOR_SIGNATURE;
const rawMessageLength = new Blob([rawMessage]).size;
const message = ethers.utils.toUtf8Bytes(
  "\x19Ethereum Signed Message:\n" + rawMessageLength + rawMessage
);
const messageHash = ethers.utils.keccak256(message);

export const getParamsWithConnector = (connectedAccount: string) => ({
  [ConnectorNames.WalletConnect]: {
    method: "eth_sign",
    params: [connectedAccount, MESSAGE_INVESTOR_SIGNATURE],
  },
  [ConnectorNames.MetaMask]: {
    method: "personal_sign",
    params: [MESSAGE_INVESTOR_SIGNATURE, connectedAccount],
  },
});

const useWalletSignature = () => {
  const dispatch = useDispatch();
  const connector = useTypedSelector((state) => state.connector).data;
  const { provider: library, account: connectedAccount } = useWeb3React();
  const [error, setError] = useState("");
  const [signature, setSignature] = useState("");

  const signMessage = useCallback(async () => {
    try {
      if (connectedAccount && library && connector) {
        const paramsWithConnector =
          getParamsWithConnector(connectedAccount)[connector as connectorNames];
        const provider = library.provider;

        setError("");

        if (connector === ConnectorNames.WalletConnect) {
          const signer = await library?.getSigner();
          const signature = await signer?.signMessage(
            MESSAGE_INVESTOR_SIGNATURE
          );
          signature && setSignature(signature);
        } else {
          await (provider as any).sendAsync(
            {
              method: paramsWithConnector.method,
              params: paramsWithConnector.params,
            },
            async function (err: Error, result: any) {
              if (err || result.error) {
                const errMsg =
                  err.message || (err as any).error || result.error.message;
                console.log("useWalletSignature", errMsg);
                dispatch(alertFailure(getErrorMessage(err)));
                setError(errMsg);
              } else {
                result.result && setSignature(result.result);
              }
            }
          );
        }
      }
    } catch (err: any) {
      console.log("useWalletSignature", err);
      dispatch(alertFailure(getErrorMessage(err)));
      // dispatch(alertFailure(TRANSACTION_ERROR_MESSAGE));
      setError(err.message);
    }
  }, [library, connector, connectedAccount]);

  return {
    signMessage,
    signature,
    setSignature,
    error,
  };
};

export default useWalletSignature;
