import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    container: {
      maxWidth: "1320px",
      margin: "0 auto",
      padding: "120px 20px",
      [theme.breakpoints.down("sm")]: {
        padding: "50px 20px",
      },
    },
    mainBox: {
      padding: "38px 20px 54px",
      background: "linear-gradient(91.5deg, #ECF5FF 1.28%, #FFFFFF 135.26%)",
      border: "1px solid #A5CCFF",
      borderRadius: "32px",
      textAlign: "center",
      "& h6": {
        fontSize: "60px",
        lineHeight: "60px",
        fontWeight: 500,
        color: "#0055D6",
      },
      "& p": {
        padding: "15px 0",
        fontSize: "20px",
        lineHeight: "24px",
      },
      "& button": {
        display: "flex",
        gap: "10px",
        height: "39px",
        margin: "0 auto",
        padding: "0 25px 4px",
        justifyContent: "center",
        alignItems: "center",
        background: "#0058FF",
        border: "none",
        borderRadius: "45px",
        fontSize: "16px",
        lineHeight: "16px",
        color: "#FFFFFF",
      },
      "& img": {
        marginTop: "4px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "20px",
        "& h6": {
          fontSize: "30px",
          lineHeight: "30px",
          fontWeight: 600,
        },
        "& p": {
          padding: "10px 0",
          fontSize: "18px",
          lineHeight: "20px",
        },
        "& button": {
          width: "256px",
          height: 44,
        },
      },
    },
  };
});

export default useStyles;
