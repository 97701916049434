import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    wrapper: {
      backgroundImage: "transparent",
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% auto",
      backgroundPosition: "0px 80px",
      display: "flex",
      // flexDirection: 'column',
      alignItems: "flex-start",
      justifyContent: "center",
      // alignContent: 'center',
      color: "#FFF",
      fontFamily: "",
      position: "relative",
      paddingTop: 32,
      paddingBottom: 20,

      "& .content": {
        width: "100%",
        maxWidth: "1320px",
        padding: "0 20px",
        [theme.breakpoints.down("xs")]: {
          // padding: '20px 24px',
        },
        "& h1": {
          paddingBottom: "22px",
          fontSize: "48px",
          lineHeight: "48px",
          fontWeight: 500,
          color: "#000B60",
        },
        [theme.breakpoints.down("sm")]: {
          "& h1": {
            fontSize: "40px",
            lineHeight: "40px",
          },
        },
      },
      "& .controller-area": {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
        [theme.breakpoints.down("xs")]: {
          flexDirection: "column",
          alignItems: "flex-start",
        },
        // justifyContent: 'space-between',

        marginBottom: "20px",

        "& .controller-area__right": {
          display: "flex",
          flexDirection: "row",
          maxWidth: "100%",
          marginLeft: "auto",
          [theme.breakpoints.down("xs")]: {
            width: "100%",
            marginLeft: "0",
          },
        },

        "& .controller-area__search": {
          position: "relative",
          width: 300,
          maxWidth: "100%",
          height: 44,

          "& input": {
            background: "#222228",
            border: "1px solid #44454B",
            boxSizing: "border-box",
            borderRadius: "4px",
            outline: "none",
            color: "#fff",
            width: "100%",
            height: 36,
            padding: "0 60px 0 12px",
          },
          "& input:placeholder": {
            font: "normal normal normal 14px/24px ",
            color: "rgba(153, 153, 153, 0.5)",
          },
          "& img": {
            position: "absolute",
            right: "12px",
            transform: "translateY(-50%)",
            top: "50%",
          },
          [theme.breakpoints.down("sm")]: {
            width: 200,
          },
          [theme.breakpoints.down("xs")]: {
            width: "100%",
          },
        },

        "& select": {
          textOverflow: "ellipsis",
          maxWidth: "100%",
          backgroundColor: "#292929",
          width: 160,
          height: 34,
          border: "1px solid #44454B",
          outline: "none",
          borderRadius: "4px",
          color: "#FFF",
          padding: "0 12px",
          position: "relative",
          font: "14px/24px ",
        },

        "& .form-control-label": {
          [theme.breakpoints.down("sm")]: {
            width: "100%",
            maxWidth: "100%",
          },
          "&:not(:first-child)": {
            marginLeft: 6,
            [theme.breakpoints.down("sm")]: {
              marginLeft: "15px",
            },
          },
          display: "flex",
          flexDirection: "column",
          "& span": {
            fontWeight: 700,
            color: "#FFFFFF",
            marginBottom: "8px",
          },
        },
      },

      "& .pool-area": {
        borderRadius: "24px",
        [theme.breakpoints.down("sm")]: {
          padding: "10px 0px",
          borderRadius: "12px",
          width: "100%",
        },
      },

      message: {
        color: "white",
        fontSize: 14,
        position: "absolute",
        top: 0,
        left: "50%",
        width: "1040px",
        maxWidth: "calc(100vw - 80px)",
        transform: "translateX(-50%)",
        padding: "12px 0",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#591425",
        marginBottom: 15,
        borderRadius: "8px",
      },

      [theme.breakpoints.down("sm")]: {
        paddingTop: 20,
      },
    },
    description: {
      font: "normal normal normal 14px/18px ",
      color: "#FFF",
      textAlign: "center",
      marginTop: "16px",
      marginBottom: "32px",
    },

    loader: {
      height: 300,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    loaderText: {
      fontWeight: 700,
      marginTop: 20,
      color: "#999999",
      font: "normal normal bold 14px/18px ",
    },

    group: {
      marginTop: "16px",

      "& .input-group": {
        background: "#222228",
        border: "1px solid #44454B",
        borderRadius: "4px",
        display: "flex",
        alignItems: "center",
        padding: "4px",
      },

      "& input": {
        color: "#fff",
        background: "#222228",
        border: "none",
        outline: "none",
        padding: "6px 8px",
        flex: 1,
        fontSize: "16px",
        lineHeight: "24px",
      },
      "& button#btn-max-deposit": {
        font: "normal normal bold 12px/14px ",
        color: "#FFF",
        backgroundColor: "#6398FF",
        borderRadius: "4px",
        width: "auto",
        padding: "4px 10px",
        marginRight: "6px",
        cursor: "pointer",
      },
      "& .balance": {
        color: "#FFF",
        font: "normal normal 500 16px/24px ",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginLeft: "4px",

        [theme.breakpoints.down("sm")]: {
          textAlign: "left",
          flexDirection: "column",
        },

        "& span:last-child": {
          fontWeight: "bold",
          fontSize: "20px",
          lineHeight: "24px",
          color: "#6398FF",

          [theme.breakpoints.down("sm")]: {
            marginTop: 4,
          },
        },
      },
      "& .subtitle": {
        color: "#FFF !important",
        font: "normal normal 500 16px/24px  !important",
        margin: "19px 4px 6px",
      },
    },
    stages: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",

      "& .stage": {
        flex: "0 0 49%",
        padding: "6px",
        textAlign: "center",
        borderBottom: "2px solid #C4C4C4",
        color: "white",
        fontFamily: "",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "24px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        "& img": {
          marginRight: "8px",
        },
      },

      "& .stage.active": {
        color: "#6398FF",
        borderBottomColor: "#6398FF",
      },
    },
    message: {
      color: "white",
      fontSize: 14,
      position: "absolute",
      top: 10,
      left: "50%",
      width: "1120px",
      // maxWidth: 'calc(100vw - 80px)',
      transform: "translateX(-50%)",
      padding: "12px 10px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "#591425",
      marginBottom: 15,
      borderRadius: "8px",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        position: "relative",
      },
    },
    messageDuration: {
      color: "white",
      fontSize: 14,
      width: "100%",
      padding: "12px 20px",
      display: "flex",
      alignItems: "center",
      background: "#591425",
      marginBottom: 15,
      borderRadius: "8px",
    },
    submittingDialog: {
      // position: "fixed",
      width: "100%",
      height: "100%",
      "& .MuiBackdrop-root": {
        // backgroundColor: "rgba(3, 9, 46, 0.6)",
      },
      "& .MuiPaper-rounded": {
        background: "none",
        boxShadow: "none",
      },

      "& .content": {
        padding: 40,
        background: "#FFFFFF",
        borderRadius: 28,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",

        "& img": {
          position: "absolute",
          top: "10px",
          right: "10px",
          cursor: "pointer",
        },
        "& .loading": {
          position: "relative",
          width: "48px",
          height: "48px",
          top: 0,
          right: 0,
        },
        "& .title": {
          marginTop: 20,
          fontSize: "32px",
          lineHeight: "32px",
          fontWeight: 600,
          color: "#000B60",
        },
        [theme.breakpoints.down("sm")]: {
          padding: "40px 20px 30px",
        },
      },
    },
  };
});

export const useSwitchStyle = makeStyles((theme) => {
  return {
    root: {
      width: 38,
      height: 21,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 2,
      "&$checked": {
        transform: "translateX(16px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: "#3232DC",
          opacity: 1,
          border: "none",
        },
      },
      "&$focusVisible $thumb": {
        color: "#3232DC",
        border: "6px solid #fff",
      },
    },
    thumb: {
      width: 18,
      height: 18,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid #A4A4A4`,
      backgroundColor: "#A4A4A4",
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
  };
});

export default useStyles;
