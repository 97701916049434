import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import BigNumber from "bignumber.js";
import { useEffect, useMemo, useRef, useState } from "react";
import useCommonStyle from "../../../styles/CommonStyle";
import usePoolStyles from "../Pool/style";
import useStyles from "./style";
import { formatDecimal } from "../../../utils";
import ProgressMovement from "../../../components/Base/Progress";

const closeIcon = "/images/icons/close.svg";
const DEFAULT_TOKEN_DECIMALS = 18; // RWA decimals

const ModalStake = (props: any) => {
  const styles = useStyles();
  const poolStyles = usePoolStyles();
  const commonStyles = useCommonStyle();
  const progressRef = useRef<any>();

  const {
    open,
    onConfirm,
    onClose,
    amount,
    setAmount,
    tokenDetails,
    stakingAmount,
    min,
    max,
    tokenBalance,
    wrongChain,
    tokenAllowance,
    handleApprove,
  } = props;

  const [progress, setProgress] = useState("0");
  useEffect(() => {
    setProgress(
      (
        ((!!Number(amount || 0) ? Number(amount || 0) : 0) /
          (!!Number(tokenBalance || 0) ? Number(tokenBalance || 0) : 0)) *
        100
      ).toFixed(0) || "0"
    );
  }, [amount, tokenBalance, setProgress]);

  const needApprove = useMemo(() => {
    return new BigNumber(tokenAllowance).lt(amount);
  }, [tokenAllowance, amount]);

  const tokenDecimal = useMemo(
    () => tokenDetails?.decimals || DEFAULT_TOKEN_DECIMALS,
    [tokenDetails?.decimals]
  );

  const handleChangeStakeAmount = (event: any) => {
    let newAmount = formatDecimal(
      new BigNumber(event.target.value),
      tokenDecimal
    );
    let maxAmount =
      !Number(max) || new BigNumber(tokenBalance).lt(max) ? tokenBalance : max;
    setAmount(new BigNumber(newAmount).gt(maxAmount) ? maxAmount : newAmount);
  };

  const onChangeStakeAmount = (percent: number) => {
    let tokenAmount = new BigNumber(tokenBalance).multipliedBy(percent);
    let newAmount =
      !max ||
      new BigNumber(tokenAmount).lt(new BigNumber(max)) ||
      new BigNumber(max).eq(0)
        ? tokenAmount + ""
        : max;
    setAmount(formatDecimal(newAmount, tokenDecimal));
  };

  const alreadyStake = stakingAmount && Number(stakingAmount) > 0;

  const progressStyle = useMemo(() => {
    if (!progressRef?.current) return 0;
    const maxWidth = progressRef?.current?.clientWidth;
    const maxItem = 52;
    if ((Number(progress) / 100) * maxWidth < maxItem / 2) {
      return 0;
    }
    if ((Number(progress) / 100) * maxWidth > maxWidth - maxItem / 2) {
      return maxWidth - maxItem;
    }
    return (Number(progress) / 100) * maxWidth - maxItem / 2;
  }, [progress]);

  return (
    <Dialog
      open={open}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={commonStyles.modal + " " + styles.modalStake}
    >
      <div className={poolStyles.modalContent}>
        <DialogTitle
          id="alert-dialog-slide-title"
          className={poolStyles.modalHeader}
        >
          <img src={closeIcon} alt="" onClick={onClose} className="btn-close" />
          <div className="title">Stake</div>
        </DialogTitle>
        <DialogContent className={poolStyles.modalBody}>
          <div className="token-type">
            <div className="token-type-title">Token</div>
            <div className="token-detail">
              <div>{tokenDetails?.symbol}</div>
            </div>
          </div>
          {alreadyStake && (
            <div className="token-type">
              <div className="token-type-title">Staking</div>
              <div className="token-detail">{stakingAmount}</div>
            </div>
          )}
          {min && Number(min) > 0 && (
            <div className="token-type">
              <div className="token-type-title">Min Stake</div>
              <div className="token-detail token-min-stake">{min}</div>
            </div>
          )}
          <div className="subtitle">
            {alreadyStake ? "Additional stake amount" : "Stake Amount"}
            <div className="token-balance">
              (Balance: {new BigNumber(tokenBalance).toFixed(2)}{" "}
              {tokenDetails?.symbol})
            </div>
          </div>
          <div className="input-group">
            <input
              value={amount}
              onChange={handleChangeStakeAmount}
              type="number"
              min="0"
            />
            <span>{tokenDetails?.symbol}</span>
          </div>

          <div className={poolStyles.groupButtonPercent}>
            <button
              onClick={() => onChangeStakeAmount(0.25)}
              className={poolStyles.btnSelectPercent}
            >
              25%
            </button>
            <button
              onClick={() => onChangeStakeAmount(0.5)}
              className={poolStyles.btnSelectPercent}
            >
              50%
            </button>
            <button
              onClick={() => onChangeStakeAmount(0.75)}
              className={poolStyles.btnSelectPercent}
            >
              75%
            </button>
            <button
              onClick={() => onChangeStakeAmount(1)}
              className={poolStyles.btnSelectPercent}
            >
              100%
            </button>
          </div>
        </DialogContent>

        <div
          className={`${poolStyles.progressArea} ${poolStyles.modalProgress}`}
        >
          <ProgressMovement
            amount={amount}
            maxAmount={tokenBalance}
            setAmount={setAmount}
          />
        </div>

        <DialogActions className={poolStyles.modalFooter}>
          {needApprove ? (
            <button
              className={`${poolStyles.btn} ${poolStyles.btnModal} ${poolStyles.btnEnable}`}
              onClick={() => handleApprove()}
              disabled={wrongChain}
            >
              Approve
            </button>
          ) : (
            <button
              className={`${poolStyles.btn} ${poolStyles.btnModal} ${poolStyles.btnStakeModal}`}
              onClick={onConfirm}
              disabled={isNaN(amount) || Number(amount) <= 0}
            >
              Stake
            </button>
          )}
          <button
            className={`${poolStyles.btn} ${poolStyles.btnModal} ${poolStyles.btnGetPkfModal}`}
            onClick={() => window.open(``)}
          >
            Get RWA
          </button>
        </DialogActions>
        {/* {transactionHashes[0].isApprove && <p className={styles.notice}>Please be patient and no need to approve again, you can check the transaction status on Etherscan.</p>} */}
      </div>
    </Dialog>
  );
};

export default ModalStake;
